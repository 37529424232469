@mixin h7 {
  font-weight: normal;
  font-size: 17px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto 30px auto;
  padding: 0 33px;
  &:before {
    content: '';
    background: $color-black;
    width: 100%;
    height: 2px;
    display: inline-block;
    position: absolute;
    top: 6px;
    top: 43%;
    left: 0;
  }
  .inner,
  &__inner {
    background: $color-white;
    display: inline-block;
    position: relative;
    padding: 0 7px 0 9px;
  }
}

//Login-page
.social-login {
  padding-bottom: 25px;
  padding-left: 0;
  &__container {
    text-align: center;
    padding: 20px 0;
  }
  &__divider {
    @include h7;
    width: 100%;
    margin: 15px auto 0;
    margin-bottom: 21px;
  }
  &__terms {
    margin-top: 15px;
    text-align: left;
    p {
      margin: 0;
    }
  }
  &__disclaimer {
    margin-bottom: 20px;
  }
  &__title,
  &__info {
    display: none;
  }
  label {
    text-transform: none;
  }
  .social-login-para,
  &__email-opt-in {
    text-align: left;
    padding-top: 20px;
  }
}
/* GNAV styling */
.site-utils__dropdown__content {
  .social-login {
    &__container {
      padding: 22px 28px 0 24px;
    }
  }
}
/* Disconnect button styling */
#vkontakte_disconnect,
#facebook_disconnect {
  display: inline;
  @include breakpoint($medium-up) {
    margin-left: 20px;
  }
  @include breakpoint($medium-landscape-only) {
    display: inline-flex;
  }
  input.form-submit {
    height: 18px;
    margin-bottom: 4px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 14px;
    margin-right: 68.5%;
    @include breakpoint($medium-up) {
      margin-right: 0;
    }
  }
}
/* Order confirmation page */
.checkout {
  &__confirm-container {
    .checkout {
      &__content {
        .social-login {
          padding-left: 20px;
          &__container {
            text-align: center;
            padding-bottom: 0;
          }
          &__terms,
          &__divider {
            display: none;
          }
          &__title {
            display: block;
          }
          &__info {
            margin-bottom: 10px;
            display: block;
          }
          &__email-opt-in {
            text-align: center;
          }
        }
      }
    }
  }
}
/* Social info section on Account preferences page */
.social-connect {
  .social-info {
    margin-top: 30px;
    &__content {
      padding-left: 0px;
    }
    &__connect {
      margin-bottom: 30px;
      line-height: 20px;
      padding-top: 20px;
      display: inline-flex;
    }
  }
}

.social-clear {
  clear: both;
}

.field-collection-slides {
  .appt-book {
    .appt-book-sections-container {
      .confirm {
        .confirm-container {
          .sign-in-container {
            .sign-in-form-container {
              input.login {
                &__submit {
                  margin-bottom: 1%;
                }
              }
              input.social-login__opt-in-checkbox {
                width: auto;
              }
            }
          }
        }
      }
      .social-login {
        &__email-opt-in,
        &__terms {
          text-align: left;
          padding-left: 0;
          width: 100%;
          @include breakpoint($medium-up) {
            padding-left: 16%;
            width: 85%;
          }
        }
      }
    }
  }
}
