.styleguide {
  background-color: $color-black;
  &__inner {
    max-width: 1024px;
    margin: 0 auto;
  }
  &__section {
    margin: 0 45px;
    &__title {
      color: $color-white;
      margin: 0;
      padding: 0.75em 0 0.5em;
      text-align: center;
      border-bottom: solid 1px $color-black;
    }
  }
  &__subsection {
    background-color: $color-white;
    &--typefaces {
      font-size: 30px;
    }
    &__title {
      background-color: $color-bg;
      color: $color-white;
      margin: 0;
      padding: 0.75em 0 0.5em;
      text-align: center;
      border-bottom: solid 1px $color-black;
    }
  }
  &__specimen {
    position: relative;
    border-bottom: solid 1px #ccc;
    min-height: 50px;
    padding: 2em 20px;
    &__title {
      position: absolute;
      background: orangered;
      color: $color-white;
      height: 30px;
      min-width: 30px;
      border-radius: 15px;
      top: 10px;
      left: -10px;
      font-family: Arial, sans-serif;
      font-size: 12px;
      text-align: center;
      padding: 0 8px;
      line-height: 30px;
    }
    &__content {
      padding-top: 10px;
    }
  }
}
