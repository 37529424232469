.optanon-show-settings-popup-wrapper {
  display: inline-block;
  .optanon-show-settings-button {
    .optanon-show-settings-left,
    .optanon-show-settings-middle,
    .optanon-show-settings-right {
      background: none !important;
      border: 0 !important;
      height: auto !important;
      width: auto !important;
    }
    .optanon-show-settings-middle {
      float: none;
      padding-top: 0;
      .optanon-show-settings {
        font-size: 14px;
        font-weight: 300;
        text-decoration: underline;
        color: $black !important;
        font-family: $font--mono;
        text-transform: uppercase;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
    .optanon-show-settings-right {
      float: none;
    }
  }
}

button {
  &#ot-sdk-btn {
    &.ot-sdk-show-settings {
      background-size: 15px !important;
      padding: 0 30px !important;
      height: 30px !important;
      margin-top: 5px !important;
      border: 1px solid $gray !important;
      text-transform: capitalize;
      font-family: $font--akkurat-bold !important;
      font-size: 14px !important;
      text-decoration: underline !important;
      color: $dark-gray !important;
      background-color: $color-white;
      &:hover {
        text-decoration: none !important;
        color: $dark-gray !important;
        background-color: $color-white;
      }
    }
  }
}

#onetrust-pc-sdk {
  &.ot-sdk-container {
    .pc-header {
      .pc-logo-container {
        .pc-logo {
          width: auto;
          height: auto;
        }
      }
      .pc-title-container {
        width: 100%;
        @include breakpoint($medium-up) {
          width: calc(100% - 190px);
        }
        #pc-title {
          font-size: 1.3em;
          &:before,
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

#onetrust-consent-sdk {
  #onetrust-banner-sdk {
    &.otFlat {
      font-family: $font--sans;
      font-size: 15px;
      #onetrust-group-container {
        width: 100%;
        #onetrust-policy {
          #onetrust-policy-text {
            margin-bottom: 0;
            color: $color-white;
            margin-top: 10px;
          }
        }
      }
      #onetrust-button-group-parent {
        width: 95%;
        text-align: #{$rdirection};
        #onetrust-button-group {
          button {
            background: transparent;
            border: none;
            text-decoration: underline;
            width: auto;
            font-weight: bold;
            opacity: 1;
          }
        }
      }
      #onetrust-close-btn-container {
        display: block;
        .onetrust-close-btn-handler {
          top: auto;
          #{$ldirection}: 90%;
          bottom: 24px;
          height: 12px;
          @include breakpoint($medium-up) {
            #{$ldirection}: 96%;
          }
        }
      }
      #onetrust-close-btn-container-mobile {
        display: none !important;
      }
    }
  }
}
