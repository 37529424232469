// @todo styleguide updates - commented out styles are so styleguide can prevail
// @todo remove commented code

/* Chat Index Page UI */
.chat-index-page {
  text-align: center;
  text-transform: uppercase;
  &__chat-link {
    width: 100%;
    padding: 8px;
    //    letter-spacing: 0.06em;
  }
  &__queries {
    margin: 15px 0;
    p {
      margin-bottom: 0;
    }
  }
  &__contact {
    margin-top: 20px;
  }
  &__back {
    text-align: left;
  }
}
/* Pre-Chat Page UI */
.prechat-page {
  &__required,
  &__sub-header {
    margin-bottom: 10px;
    line-height: 18px;
  }
  &__fields {
    input {
      height: 40px;
      padding: 10px;
      width: 100%;
      border: 1px solid $color-black;
      margin-bottom: 10px;
      line-height: 1;
      &.adpl--processed + label {
        line-height: 1.9em;
      }
    }
    select {
      height: 40px;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  &__begin {
    width: 100%;
    height: auto;
    margin: 10px 0;
    //    letter-spacing: 0.06em;
  }
  .error_messages {
    margin-bottom: 10px;
    li {
      margin-bottom: 5px;
    }
  }
}
/* Landing Page UI */
.chat-landing-page {
  &__window {
    width: 100%;
    height: 250px;
    font-size: 12px;
    padding: 10px;
    margin-bottom: 15px;
    overflow-y: scroll;
    text-transform: uppercase;
    border: 1px solid $color-black;
    .server,
    .client {
      margin-bottom: 10px;
      line-height: 18px;
      .by {
        font-weight: bold;
      }
      .text {
        word-wrap: break-word;
      }
    }
  }
  &__button-container {
    .chat-landing-page__question {
      width: 100%;
      height: 40px;
      padding: 10px;
      border: 1px solid $color-black;
      margin-bottom: 10px;
      line-height: 1;
    }
    .chat-landing-page__button {
      @include button();
      width: 100%;
      height: auto;
      //      letter-spacing: 0.06em;
    }
  }
  &__close {
    display: inline-block;
    margin: 5px 0 15px;
    text-transform: uppercase;
  }
  &__typing {
    display: none;
    margin-top: 10px;
  }
  &__ended {
    display: none;
    &-header {
      // @todo use heading?
      @include font--subheading-alt;
      font-size: 24px;
      margin-top: 10px;
      //      letter-spacing: 0.06em;
      //      line-height: 1;
    }
    &-tag {
      @include font--subheading-alt;
      font-size: 20px;
      margin-bottom: 10px;
      //      letter-spacing: 0.06em;
      line-height: 1;
    }
    &-instruction {
      margin-bottom: 10px;
      line-height: 18px;
    }
    &-link {
      display: none;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    &-button {
      display: none;
      margin-bottom: 15px;
      text-align: center;
      width: 100%;
    }
  }
  &__unavailable {
    text-align: center;
    text-transform: uppercase;
    &-header {
      // @todo use heading?
      @include font--subheading-alt;
      font-size: 24px;
      margin: 10px;
      //      letter-spacing: 0.06em;
    }
    &-back {
      display: block;
      text-align: left;
    }
    p {
      //      line-height: 20px;
    }
  }
  .end-chat-overlay {
    display: none;
    background-color: $color-black;
    width: 100%;
    position: absolute;
    opacity: 0.5;
    left: 0;
    top: 0;
  }
  .end-chat-popup {
    display: none;
    position: absolute;
    background-color: $color-white;
    padding: 10px;
    z-index: 1;
    &__end,
    &__continue {
      width: 48%;
      text-align: center;
      //      letter-spacing: 0.06em;
    }
  }
}

.prechat-page,
.chat-index-page,
.chat-landing-page {
  font-size: 14px;
  padding: 15px;
  &__header {
    // @todo use headings?
    @include font--subheading-alt;
    font-size: 24px;
    margin: 10px;
    text-align: center;
    //    letter-spacing: 0.06em;
  }
  //   a {
  //     text-decoration: underline;
  //   }
  //   .button {
  //     text-decoration: none;
  //   }
}
