// added new class variant - select--wide for min 300px wide version
$select-box-height: 3em;

.select-box {
  display: inline-block;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  &.select--wide {
    min-width: 300px;
  }
  &--replaced {
    display: none;
  }
  &--disabled {
    cursor: not-allowed;
  }
  &__label {
    @include icon('caret--down', after);
    display: inline-block;
    position: relative;
    padding: 0 4em 0 1em;
    line-height: $select-box-height;
    border: $border-form;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    border-radius: 10px;
    .select--wide & {
      min-width: 300px;
    }
    .select-box.checkedEmpty & {
      color: $color-red;
      border-color: $color-red;
    }

    // The icon content is also in the :after pseudoelement.
    &:after {
      color: $color-form-grey;
      position: absolute;
      top: 0;
      right: 0;
      width: $select-box-height;
      line-height: $select-box-height;
      text-align: center;
      border-left: $border-form;
      .select-box.checkedEmpty & {
        color: $color-red;
        border-color: $color-red;
      }
    }
  }
  &__options {
    display: none;
    position: absolute;
    top: $select-box-height;
    width: 100%;
    max-height: $select-box-height * 8;
    margin: 0;
    padding: 0;
    background: $color-white;
    border: $border-form;
    cursor: pointer;
    overflow: auto;
    z-index: 4;
    .select--wide & {
      min-width: 300px;
    }
    &.open {
      display: block;
      border-radius: 10px;
    }
  }
  &__option {
    display: block;
    position: relative;
    padding: 0 1em;
    line-height: $select-box-height;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: $border-form;
    text-align: left;
    &:last-child {
      border-bottom: none;
    }
  }

  // This element appears in shade dropdowns.
  &__swatch {
    display: block;
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    height: 2em;
    width: 2em;
  }
} // .select-box
