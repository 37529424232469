///
/// @file template_api/endeca/_search_results.scss
///
/// \brief Search Results
///

.search-wrapper {
  &__loading {
    text-align: center;
    padding-bottom: 20px;
  }
  &__header {
    @include h1;
    text-align: center;
  }
  &__results {
    margin: 0 auto;
    max-width: $max-width-large;
    .product-grid {
      &--small-cols-2 {
        .product-grid__item {
          float: left;
          @include breakpoint($small-up) {
            min-height: 260px;
          }
          @include breakpoint($small-portrait) {
            min-height: 370px;
          }
          @include breakpoint($large-up) {
            min-height: 430px;
          }
        }
      }
    }
  }
  .summary {
    padding: 20px 0;
    text-align: center;
    text-transform: uppercase;
    span {
      font-weight: bold;
      .not-bold {
        font-weight: normal;
      }
    }
  }
  .results {
    &__products {
      max-width: $mpp-max-width;
      .products {
        &__header {
          // temp hide sort
          display: none;
        }
      }
    }
    &__content {
      .results-summary {
        // temp hide sort
        display: none;
      }
    }
  }
  &__bestsellers {
    // This is the main wrapper for the "no results" content
    .bestseller-results {
      padding-top: 40px;
    }
  }
}

// Search results page:
.search-results {
  padding: 0;
  @include breakpoint($landscape-up) {
    padding: 0 20px;
  }
}

@include breakpoint($landscape-up) {
  body.toolbar.device-pc {
    .page-wrapper {
      .search-results__header {
        padding-top: 2em;
      }
    }
  }
}

.search-results__header {
  padding: 0;
  @include breakpoint($landscape-up) {
    padding: 4.1em 0 2em 0;
    display: block;
  }
  &--results {
    min-height: auto;
  }
  .search-form--results {
    min-height: auto;
  }
}

.search_results__summary {
  padding: 0 10px;
  text-align: center;
}

// smashbox_base/template_api/endeca/esearch_results_pc_v1.mustache
.results__header--column {
  padding-left: 21px;
  border-top: $color-black solid 1px;
  border-bottom: $color-black solid 1px;
  margin-bottom: 1em;
  a {
    text-decoration: none;
  }
  @include breakpoint($landscape-up) {
    border: none;
    width: 20%;
    float: left;
    height: 100%;
    position: relative;
    display: block;
    padding-left: 1em;
    padding-bottom: 1em;
  }
}

.results__products--column {
  margin: 0;
  border-bottom: $color-black solid 1px;
  @include breakpoint($landscape-up) {
    border: none;
    margin: 0 0 0 20%;
    padding-right: 1em;
    padding-bottom: 60px;
    .product-grid {
      top: 0px;
      margin-top: 32px;
    }
  }
  .results__header--dimensions {
    text-align: center;
    margin-bottom: 2em;
  }
}

.esearch-product {
  width: 100%;
}

.results__header-filter-content--mobile-collapsed {
  display: none;
  @include breakpoint($landscape-up) {
    display: block;
  }
}

.breadcrumbs__link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

.breadcrumbs__link-close {
  font-size: 12px;
  height: 12px;
  line-height: 9px;
  width: 12px;
}
// plain styles for responsive result on search page
.endeca-product-result {
  padding: 1em 0;
}

// dimensions / filters
.results-filter__header {
  @include icon('plus', after);
  &.collapse {
    @include icon('minus', after);
  }
  &:after {
    font-size: 12px;
    padding: 0 0 6px 10px;
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}

.results__header {
  text-transform: uppercase;
  .results-header {
    font-weight: normal;
    font-size: 18px;
    font-family: $font--subheading-alt;
    cursor: pointer;
    margin-top: 1em;
    &.dimensions__header {
      font-size: 24px;
    }
  }
}
