#offer_chat div {
  line-height: 17px !important;
  letter-spacing: 1px !important;
}

#lpChat .lp_location_bottom {
  .lp_bottom_area {
    .lp_action_wrapper {
      .lp_title {
        text-transform: none;
      }
    }
  }
}

.device-mobile {
  #footer_chat_link .button {
    padding: 4px 0 4px 23px;
  }
}

.device-pc {
  #lpChat .lp_maximized {
    left: 20px;
  }
}

.loyalty-panel {
  &__header {
    margin: 1.5em auto;
  }
  &__image-container {
    text-align: center;
    img {
      width: 270px;
      @include breakpoint($medium-up) {
        width: 400px;
      }
    }
  }
  &--smashcash {
    .loyalty-panel__content {
      background: $light-gray;
      .smashcash-benefits {
        padding: 3em 0;
      }
    }
    &-about {
      margin-top: 2em;
      @include breakpoint($landscape-up) {
        background: $light-gray url('/media/export/cms/account/loyalty/footer-banner.jpg') no-repeat
          center top;
        border-bottom: none;
      }
      .loyalty-panel {
        &__header {
          width: 303px;
          img {
            margin-bottom: 0;
            width: 270px;
          }
          h1 {
            font-family: $font--Neue-Haas-Grotesk-Display-Black;
            font-size: 28px;
            text-transform: none;
            color: $medium-black;
            margin: 8px 0;
            @include breakpoint($landscape-up) {
              font-size: 32px;
            }
          }
          p {
            font-family: $font--helvetica;
            margin: 2px 0 12px;
            text-transform: none;
            font-size: 19px;
          }
        }
        &__content {
          .smashcash-about-button {
            width: 20%;
            padding: 10px;
          }
        }
      }
    }
  }
}

.password-reset-page {
  .password-reset__item {
    &.terms_conditions_txt {
      width: 100%;
      position: relative;
      padding-left: 23px;
    }
    input[type='checkbox'] {
      width: auto;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
}

.page-wrapper {
  .account-section {
    .profile-page__content {
      .newsletter-info__fieldset {
        width: 100%;
        margin-bottom: 25px;
      }
      .sms-promotions {
        display: none;
      }
      input.form-submit {
        float: unset;
      }
      .optional-info {
        display: block;
      }
    }
  }
  .order-details-page {
    .order-details-page__content {
      .order-total {
        th {
          width: 70%;
        }
      }
    }
  }
}
.apple-pay-button {
  -apple-pay-button-type: check-out;
  background: $color-white;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
}

#cboxClose {
  top: 20px;
  #{$rdirection}: 45px;
  @include breakpoint($medium-up) {
    top: 40px;
  }
  &::before {
    font-size: 15px;
    line-height: 1;
  }
}
.elc-product-full {
  .elc-right-column-section {
    .elc-product-auto-replenish {
      .elc-auto-replenish {
        &-open-modal {
          #{$ldirection}: 170px;
          @include breakpoint($landscape-up) {
            #{$ldirection}: 192px;
          }
        }
        &-subscribe-wrapper {
          p {
            width: auto;
          }
        }
      }
    }
  }
}
