// @todo styleguide updates - commented out styles are so styleguide can prevail
// @todo remove commented code

%account-page-grid {
  float: left;
  margin: 0 4% 80px 0;
  position: relative;
  width: 48%;
  &:nth-child(2n) {
    margin-right: 0;
  }
  @include breakpoint($landscape-up) {
    margin-right: 1.2%;
    width: 32.13%;
    &:nth-child(2n) {
      margin-right: 1.2%;
    }
  }
}

.past-purchases-page {
  @include breakpoint($medium-down) {
    padding-top: 0px;
    margin-top: -29px;
  }
}
/* Common Styles for Favorites and Past Purchases */
.favorites-page__content,
.past-purchases-page__content {
  .product-item__img {
    padding-bottom: 10px;
    img {
      width: 100%;
      @include breakpoint($landscape-up) {
        height: 325px;
      }
    }
  }
  .product-info,
  .product-item {
    & > div {
      padding-bottom: 10px;
      float: left;
    }
    &__text {
      color: $color-red;
      clear: both;
      display: block;
      padding-bottom: 10px;
    }
    &__actions--reviews {
      display: none;
    }
    &__actions--reorder {
      cursor: pointer;
      @include breakpoint($landscape-up) {
        float: left;
        padding: 0 10px 0 0;
      }
    }
    &__name {
      @include h3;
      width: 70%;
      @include breakpoint($landscape-up) {
        width: 75%;
      }
      a {
        text-decoration: none;
      }
    }
    &__size {
      clear: both;
    }
    &__price {
      display: none;
      &.price,
      &.past-purchase__price {
        float: right;
        display: inline-block;
        text-align: right;
        width: 30%;
        @include breakpoint($landscape-up) {
          width: 25%;
        }
      }
    }
    &__add-to-cart,
    &__actions,
    &__spp-status,
    &__subhead,
    &__img {
      width: 100%;
    }
    &__add-to-cart,
    &__actions {
      .button {
        @include breakpoint($landscape-up) {
          padding: 6px 14px;
        }
      }
    }
  }
}

#purchases {
  .past-purchases-page__content {
    float: left;
    padding: 4px 0px 0px;
    position: relative;
    width: 100%;
    @include breakpoint($landscape-up) {
      width: 70.4%;
    }
    a.icon.back-link {
      @include breakpoint($medium-down) {
        position: absolute;
        top: 0px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .past-purchases {
      margin-left: 4%;
      @include breakpoint($landscape-up) {
        margin-left: 0;
      }
      .past-purchase {
        .product-item {
          &__details {
            position: relative;
            min-height: 170px;
            @include breakpoint($medium-up) {
              min-height: 150px;
            }
            @include breakpoint($medium-portrait-only) {
              min-height: 160px;
            }
          }
          &__replenishment {
            bottom: 0;
            position: absolute;
            width: 98%;
            @include breakpoint($landscape-up) {
              width: 90%;
            }
            .select-box {
              &__label {
                padding: 0 3em 0 0.5em;
                &:after {
                  background-color: $color-white;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* Past Purchases Page */
.past-purchases-page__content {
  .past-purchases {
    padding-top: 0;
    &__title {
      @include h3;
    }
    &__link {
      margin-top: 10px;
      @include breakpoint($medium-down) {
        margin-bottom: 70px;
      }
    }
    .past-purchase {
      float: left;
      margin: 0 4% 5% 0;
      position: relative;
      width: 45%;
      @include breakpoint($landscape-up) {
        width: 48%;
      }
      @include breakpoint($medium-portrait-only) {
        width: 29%;
      }
      &:nth-child(2n) {
        @include breakpoint($landscape-up) {
          margin-right: 0;
        }
      }
      @include breakpoint($landscape-up) {
        margin-right: 1.2%;
        width: 32.13%;
        &:nth-child(2n) {
          margin-right: 1.2%;
        }
      }
      @include breakpoint($medium-down) {
        &:last-child {
          margin-bottom: 127px;
        }
      }
      .product-add-favorites {
        display: inline-block;
        font-size: 18px;
        float: none;
        padding-left: 5px;
        margin: 0;
      }
      .product-item__add-bag {
        display: none;
      }
      .product-item__details {
        width: 100%;
        & > div {
          float: none;
          padding-bottom: 10px;
          padding-right: 0;
          text-align: left;
          width: 100%;
          @include breakpoint($landscape-up) {
            float: left;
            padding-bottom: 10px;
            width: 75%;
          }
        }
        .product-item {
          @include breakpoint($landscape-up) {
            &__name {
              width: 70%;
            }
            &__price {
              text-align: right;
              width: 25%;
            }
          }
        }
        .product-item__subhead,
        .product-item__size {
          display: none;
        }
      }
      .product-item__replenishment {
        clear: both;
        width: 75%;
        .selectbox,
        .select-box__label,
        .select-box__options {
          width: 100%;
          min-width: 100%;
        }
        @include breakpoint($medium-down) {
          width: 100%;
          max-width: 172px;
        }
      }
      .pre-order-message-display {
        margin: 0 0 3px 0;
      }
    }
    @include breakpoint($medium-down) {
      border-bottom: 0px;
      margin-top: 5px;
    }
  }
}

.past-purchases-page {
  .account-section {
    &__header {
      @include breakpoint($medium-down) {
        margin: -10px 0 21px 0;
      }
    }
  }
  .product-item {
    &__add-to-cart {
      bottom: -50px;
      margin-top: 10px;
    }
    &__replenishment {
      .content--caret--down {
        @include breakpoint($medium-down) {
          border-left: #696969 solid 1px;
          height: 38px;
          padding-left: 5px;
          padding-right: 5px;
          padding-top: 11px;
          position: absolute;
          right: 0;
          width: 25px;
          z-index: -99;
        }
      }
    }
    &.product-replenish {
      .product-item__add-to-cart {
        bottom: -75px;
      }
    }
  }
}
/* Favorites Page */
.favorites-page__content {
  .favorite {
    margin-bottom: 50px;
    @extend %account-page-grid;
    &-sign-in {
      margin-bottom: 20px;
    }
    &-none__text {
      line-height: 20px;
    }
    &-none__link {
      margin-top: 10px;
      text-align: center;
      width: 100%;
      @include breakpoint($landscape-up) {
        width: auto;
      }
    }
    .product-info__actions {
      bottom: -90px;
      @include breakpoint($medium-down) {
        bottom: -56px;
      }
    }
    &-actions {
      display: inline-block;
      padding-top: 10px;
      &__remove {
        cursor: pointer;
        text-transform: uppercase;
        text-decoration: underline;
      }
      &__add-to-list {
        display: none;
      }
    }
  }
}

.favorites-page {
  @include breakpoint($medium-down) {
    padding-top: 0px;
    margin-top: -30px;
  }
  .account-section {
    &__header {
      @include breakpoint($medium-down) {
        margin: -10px 0 30px 0;
      }
    }
  }
  .product-info {
    min-height: 100px;
    &__size {
      @include breakpoint($medium-down) {
        padding: 6px 0;
      }
    }
  }
}
/* Delete Overlay */
.delete-favorite {
  &__overlay {
    .confirm-delete {
      padding: 20px;
      &__text {
        border-bottom: $border;
        text-align: left;
      }
      &__link {
        cursor: pointer;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }
  }
}
