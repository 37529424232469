/* Mixin function for review star Background image */
@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 17px;
  $pr-width-value: 17px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/power_review/stars/rating-#{$pr-value}-small.png');
      height: $pr-height-value;
      width: $pr-width-value;
      margin: 0;
      padding: 0;
    }
  }
}
/* Mixin function for pr-button display */

@mixin pr-button-display {
  background: $color-black;
  color: $color-white;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  font-family: $font--heading-main;
  font-size: 18px;
  padding: 6px 10px 6px 10px;
  border: 2px solid $color-black;
  text-align: center;
  font-weight: 100;
  &:hover {
    background: $color-white;
    border-color: $color-black;
    color: $color-black;
    text-decoration: none;
  }
}
/* Mixin function for pr-label display */

@mixin pr-label {
  color: $color-black;
  text-transform: uppercase;
  font-family: $font--heading-main;
  font-size: 18px;
  font-weight: 100;
}
/* Mixin function for review star Large Background image */
@mixin pr-large-background-image($pr-large-height-value: 26px, $pr-large-width-value: 23px, $pr-large-margin-value: 0) {
  $pr-large-count: (0, 25, 50, 75, 100);
  @each $pr-large-value in $pr-large-count {
    .pr-star-v4-#{$pr-large-value}-filled {
      background-image: url('/media/images/power_review/stars/rating-#{$pr-large-value}-large.png');
      height: $pr-large-height-value;
      width: $pr-large-width-value;
      margin: $pr-large-margin-value;
      padding: 0;
    }
  }
}
/* start of main power review container */
#power_review_container {
  padding: 50px 10px;
  @include breakpoint($small-down) {
    padding: 10px;
  }
}

.spp_customer_reviews {
  padding: 30px 20px;
  @include breakpoint($small-down) {
    padding: 15px 0;
  }
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-snapshot-snippets {
        .pr-snippet {
          position: relative;
          top: 58px;
          padding-left: 15px;
          @include breakpoint($large-down) {
            top: 15px;
            padding-left: 3px;
          }
        }
      }
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-reco-stars {
          clear: both;
          position: relative;
          @include breakpoint($large-down) {
            clear: both;
          }
        }
      }
      .pr-review-snapshot {
        &.pr-snapshot-desktop,
        &.pr-snapshot-tablet {
          margin-top: -55px;
        }
        &.pr-snapshot-mobile {
          margin-top: -13px;
          margin-left: 10px;
        }
      }
    }
    header {
      &.pr-rd-main-header {
        margin-bottom: 20px;
        @include breakpoint($large-down) {
          margin: 15px 0;
        }
      }
    }
  }
  .spp_reviews_button {
    a.review_link {
      text-transform: initial;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      @include breakpoint($large-down) {
        margin-right: 13px;
      }
    }
  }
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.product-full__details,
.spp_product__review-snippet,
#pr-reviewsnippet,
.search-wrapper__results,
.product-quickshop__content {
  .p-w-r {
    /* To display a Star background Image */
    @include pr-background-image();
    margin-left: 0;
    .product-grid-wrapper & {
      margin: 0 0 15px 0;
    }
    a {
      @include font--text;
      color: $medium-gray;
      text-transform: initial;
      &:hover {
        color: $color-black;
        text-decoration: underline;
      }
    }
    span,
    p {
      @include basic-text-reset;
      color: $medium-gray;
    }
    .pr {
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-size: 14px;
        }
      }
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal,
      &-alert-warning {
        display: none;
      }
      &-table-cell {
        padding: 0;
      }
      &-helper-text {
        color: $color-black;
        font-family: $font--subheading;
        font-size: 15px;
      }
      &-clear-all-radios {
        @include font--subheading;
        color: $color-black;
        letter-spacing: 0;
        &:hover {
          color: $color-black;
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      /* To Change Bar Graph */
      &-accessible-btn {
        @include pr-button-display();
      }
      &-header-table {
        .pr-header-title {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            .spp_wrap & {
              display: inline-block;
              a,
              span {
                @include font--text;
                text-transform: uppercase;
                text-decoration: underline;
                color: $color-black;
                font-weight: bold;
              }
            }
            .pr-snippet-write-review-link {
              .spp-cross-sells__products &,
              .product-grid-wrapper &,
              .search-wrapper &,
              .product-quickshop & {
                display: none;
              }
            }
          }
          &.pr-snippet-minimal {
            .pr-snippet-stars-container {
              display: inline-block;
            }
            .pr-snippet-read-and-write {
              margin-left: 0;
              @media only screen and (min-width: $small-portrait) {
                margin-left: 18px;
              }
              .pr-snippet-review-count {
                padding: 0 7px 0 0;
                @media only screen and (min-width: $small-portrait) {
                  padding-right: 7px;
                  margin-right: 8px;
                }
              }
              a {
                text-decoration: none;
              }
              a:hover {
                color: $color-light-gray;
                text-decoration: underline;
              }
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          @include font--text;
          color: $dark-gray;
          font-size: $base-font-size;
          width: auto;
        }
        .pr-rd-description-text {
          @include font--text;
          color: $dark-gray;
          font-size: $base-font-size;
          line-height: 22px;
        }
        .pr-rd-footer {
          padding: 0 0 20px 0;
        }
        .pr-rd-main-header {
          background: $light-gray;
          padding: 10px;
          h1 {
            display: none;
          }
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline {
        @include font--subheading;
        text-transform: uppercase;
        color: $color-black;
        font-size: 18px;
      }
      .pr-rating {
        &-number {
          border: 1px solid $color-blue;
        }
        &-date {
          border: none;
        }
      }
      .pr-text {
        color: $color-black;
        font-weight: normal;
        float: left;
        margin: 2px 0 2px 0;
        padding: 0;
      }
      .title {
        color: $color-black;
        text-transform: capitalize;
      }
      .pr-dark {
        color: $color-black;
        font-weight: bold;
        float: left;
        width: 55%;
        font-family: $font--subheading;
        text-transform: uppercase;
        font-size: 15px;
        margin-bottom: 7px;
      }
      span {
        text-transform: capitalize !important;
      }
    }
  }
}
/* Spp Page Review Container */
.spp__container {
  .p-w-r {
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-compact {
        .pr-snippet-stars-reco-stars,
        .pr-snippet-stars-reco-reco {
          float: left;
        }
        .pr-snippet-read-and-write a {
          @include breakpoint($small-down) {
            padding: 0 10px 0 0;
            margin: 0;
          }
        }
      }
    }
  }
  .spp_reviews {
    .spp_reviews_heading {
      font-family: $font--heading-main;
      text-transform: uppercase;
      font-weight: 100;
      font-size: 25px;
      line-height: 24px;
      margin-left: 14px;
    }
    .spp_reviews_button {
      margin: 25px 0 0 15px;
      @include breakpoint($small-down) {
        margin-top: 5px;
      }
    }
    .pr-review-snapshot {
      margin-top: -55px;
    }
  }
  .pr-review-snippet-container {
    .pr-snippet-stars-reco-reco {
      display: none;
    }
    .pr-snippet-stars-reco-inline {
      margin: 0;
      float: left;
      .pr-snippet-read-and-write {
        display: inline;
      }
    }
  }
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-snapshot-msq-container {
        display: none;
      }
      & :focus {
        outline: none;
        box-shadow: none;
      }
      .pr-snippet-standard {
        width: 100%;
      }
      .pr-review-snapshot-simple,
      .pr-snippet-standard,
      .pr-snippet-compact,
      .pr-snippet-minimal {
        .pr-review-snapshot-snippets {
          .pr-snippet-read-and-write a {
            display: none;
          }
        }
        .pr-snippet-reco-to-friend-green {
          background: none;
          & circle {
            display: none;
          }
          .pr-checkbox-icon {
            display: none !important;
          }
        }
        .pr-reco-to-friend-message {
          font-size: 14px;
          line-height: 26px;
          @include breakpoint($small-down) {
            width: 80%;
            padding: 0;
          }
        }
      }
      .pr-accordion-btn {
        letter-spacing: 0;
        font-weight: bold;
      }
      .pr-review {
        padding: 0 15px;
      }
      .pr-review-snapshot {
        .pr-review-snapshot-content-block {
          margin: 0;
        }
        a.pr-snippet-write-review-link {
          text-align: right;
          color: $color-black;
        }
        .pr-snippet {
          .pr-snippet-read-and-write {
            vertical-align: sub;
          }
        }
      }
      .pr-snippet-reco-to-friend {
        display: none;
        .pr-reco-to-friend-message {
          max-width: 520px;
          line-height: 20px;
          vertical-align: top;
        }
        div,
        span {
          @include font--text;
          display: inline !important;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: bold;
          @include breakpoint($medium-down) {
            display: inline !important;
          }
        }
      }
      .pr-accordion {
        .pr-accordion-btn {
          .pr-caret-icon svg {
            display: none;
          }
          .pr-caret-icon {
            @include icon('triangle-left', after);
            font-size: 8px;
            height: 10px;
            &:after {
              color: $color-black;
            }
            &:before {
              color: $color-black;
            }
          }
          .pr-caret-icon__line {
            stroke: $color-black;
          }
        }
      }
      .pr-reco-value,
      .pr-reco-to-friend-message {
        line-height: 20px;
        vertical-align: top;
      }
      .pr-review-display {
        .pr-rd-no-reviews {
          padding: 30px 0;
          @include breakpoint($small-down) {
            padding: 50px 0 20px 0;
          }
        }
      }
    }
    .pr-review-snapshot {
      &-block {
        &.pr-review-snapshot-block-histogram,
        &.pr-review-snapshot-block-cons {
          display: none;
        }
        .pr-snapshot-tag-def {
          text-align: center;
          display: inline-block;
          @include breakpoint($small-down) {
            text-align: left;
            display: inline-block;
          }
          &:first-of-type {
            padding-top: 0;
          }
        }
      }
      &.pr-snapshot-mobile {
        .pr-review-snapshot-simple {
          .pr-reco-value {
            font-weight: bold;
          }
        }
      }
      .pr-review-snapshot-snippets-headline {
        color: $color-black;
      }
    }
    .pr-review-display {
      .pr-rd-review-headline {
        color: $color-black;
        text-transform: uppercase;
        padding: 1px 0 0 0;
        line-height: 16px;
        font-size: 22px;
        font-family: $font--heading-main;
        font-weight: 400;
      }
      .pr-rd-main-footer {
        .pr-rd-content-block {
          width: 100%;
        }
        .pr-rd-to-top {
          @include font--text;
          padding-left: 31%;
          text-align: center;
          font-size: 13px;
          @include breakpoint($large-down) {
            padding: initial;
          }
        }
        .pr-rd-pagination-btn {
          @include font--text;
          text-transform: capitalize;
          color: $medium-gray;
          font-size: 13px;
          &:hover {
            text-decoration: underline;
            color: $color-black;
          }
        }
      }
      .pr-flagging-radio {
        padding-left: 20px;
      }
      .pr-rd-sort-group {
        select {
          @include font--text;
          font-size: 12px;
          margin-right: 39px;
          text-transform: uppercase;
          border: 1px solid $color-black;
          max-width: 100%;
          @include breakpoint($small-down) {
            margin-right: 0;
            max-width: 98%;
          }
        }
        label,
        div#pr-rd-sort-by {
          @include basic-text-reset;
          color: $color-black;
        }
      }
      .pr-rd-def-list dd {
        font-weight: normal;
        height: 18px;
      }
      .pr-rd-subratings {
        padding-bottom: 20px;
        @include breakpoint($small-down) {
          padding-bottom: 0;
        }
      }
      .pr-rd-review-tag {
        dt {
          background: none;
          padding-bottom: 0;
          padding-top: 0;
          font-weight: bold;
        }
        dd {
          padding-bottom: 0;
        }
      }
      .pr-rd-side-content-block.pr-rd-right {
        width: 222px;
        @include breakpoint($large-down) {
          width: auto;
        }
      }
      .pr-rd-header.pr-rd-content-block,
      .pr-rd-description.pr-rd-content-block {
        margin-bottom: 0;
      }
      .pr-verified_buyer,
      .pr-verified_reviewer {
        display: none;
      }
      .pr-rd-content-block.pr-accordion {
        button.pr-accordion-btn {
          span {
            text-transform: uppercase;
            color: $dark-gray;
          }
        }
      }
      .pr-rd-header.pr-rd-content-block {
        .pr-rd-star-rating,
        .pr-rd-review-headline {
          margin-bottom: 5px;
          float: none;
          margin-left: 0;
          @include breakpoint($small-down) {
            margin-bottom: 10px;
          }
        }
      }
      .pr-rd-description.pr-rd-content-block {
        .pr-rd-description-text {
          margin-bottom: 15px;
          margin-top: 15px;
        }
      }
      .pr-rd-helpful-action {
        line-height: 20px;
        .pr-rd-helpful-text {
          padding-right: 3px;
          line-height: 20px;
          color: $dark-gray;
          @include breakpoint($large-down) {
            margin-bottom: 4px;
          }
        }
      }
    }
    .pr-thumbs-icon {
      display: none;
    }
    .pr-rd-bottomline {
      .pr-rd-bold {
        display: none;
      }
      span {
        color: $dark-gray;
      }
    }
    .pr-rd-reviewer-details {
      span,
      time {
        color: $dark-gray;
        font-weight: bold;
      }
    }
    .pr-rd-flag-review-container {
      @include font--text;
      font-size: 12px;
      vertical-align: top;
      line-height: 20px;
      color: $dark-gray;
      font-weight: bold;
      a {
        color: $color-black;
        text-transform: uppercase;
        vertical-align: middle;
        @include breakpoint($large-down) {
          padding-left: 10px;
        }
      }
    }
    .pr-helpful {
      &-count {
        font-size: 12px;
        text-transform: uppercase;
        padding-right: 7px;
        line-height: 20px;
        color: $color-black;
        &:hover {
          color: $color-black;
        }
      }
      &-btn {
        border: 0;
        padding: 0 1px;
        background: none;
        text-transform: capitalize;
        vertical-align: top;
        font-weight: bold;
        @include breakpoint($large-down) {
          margin: 0;
          float: left;
        }
      }
      &-yes {
        .pr-helpful-count {
          &:before {
            visibility: visible;
            margin-right: 0;
            text-transform: uppercase;
            font-size: 12px;
          }
          &:after {
            color: $color-black;
          }
        }
      }
      &-no {
        .pr-helpful-count {
          &:before {
            visibility: visible;
            margin-right: 0;
            text-transform: uppercase;
            font-size: 12px;
          }
        }
      }
      .pr-helpful-count {
        font-size: 12px;
        text-transform: uppercase;
        padding-right: 7px;
        line-height: 20px;
        color: $color-black;
      }
      .pr-rd-helpful-text {
        line-height: 20px;
        color: $dark-gray;
      }
    }
    .pr-rd-details.pr-rd-author-nickname,
    .pr-rd-details.pr-rd-author-location {
      color: $dark-gray;
      font-weight: bold;
    }
    .pr-rd-details.pr-rd-author-nickname {
      color: $color-black;
      font-size: 22px;
      text-transform: uppercase;
      font-family: $font--heading-main;
      font-weight: 400;
      margin: 8px 0;
    }
    .pr-rd-header.pr-rd-content-block {
      margin-top: 20px;
    }
    .pr-rd-images.pr-rd-content-block {
      margin: 0;
    }
    .pr-review-snapshot-faceoff {
      display: none;
    }
  }
  .product-full__sub-row {
    padding-top: 3px;
  }
}

.spp_wrap {
  .pr-snippet-reco-to-friend {
    display: none;
  }
  .product__rating.desktop-block {
    margin-top: 0;
  }
}

#pr-war-form {
  .pr-cons-form-group,
  .pr-bestuses-form-group,
  .pr-pros-form-group,
  .pr-wasthisagift-form-group {
    display: none;
  }
}

.section-review {
  #power_review_container {
    float: left;
    width: 100%;
    .product-full {
      &__container {
        max-width: 1024px;
        margin: 0 auto;
        padding: 40px 0 20px;
        position: relative;
        @include breakpoint($small-down) {
          padding: 0.5em 0 0 0;
        }
      }
      &__image {
        width: 30%;
        float: left;
        @include breakpoint($small-down) {
          width: auto;
        }
      }
      &__details {
        float: left;
        width: 55%;
        max-width: 70%;
        @include breakpoint($small-down) {
          width: auto;
          max-width: 100%;
          margin-top: 15px;
        }
        .product-name {
          @include font--text;
          position: absolute;
          left: 0;
          width: 24%;
          line-height: 28px;
          color: $color-black;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 15px;
          text-transform: uppercase;
          font-weight: bold;
          @include breakpoint($small-down) {
            top: 67px;
            width: auto;
            text-align: center;
          }
        }
        a {
          text-decoration: none;
        }
      }
    }
    .p-w-r {
      .thank-you-page {
        .col-sm-12 {
          .pr-rating-number {
            border: none;
            span {
              float: left;
            }
          }
          span {
            font-family: $font--text;
            width: 43%;
            float: left;
          }
          h5 {
            a {
              font-family: $font--text;
              color: $color-black;
              font-size: 15px;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .pr-media-preview {
        .pr-loading-indicator {
          @include breakpoint(($small-down) (orientation portrait)) {
            width: 35px;
            height: 35px;
          }
        }
      }
      .pr-btn-danger {
        @include breakpoint(($small-down) (orientation portrait)) {
          padding: 2px 5px;
        }
      }
      fieldset {
        width: auto;
      }
      .pr-header {
        border-bottom: none;
        .pr-header-table {
          margin: 25px 0 23px 0;
        }
      }
      .pr-header-title {
        margin: 0;
        color: $color-black;
        font-family: $font--subheading;
        font-size: 22px;
        line-height: normal;
        display: block;
        font-weight: normal;
        border-bottom: 2px solid $color-black;
      }
      label {
        margin: 5px 0;
      }
      span {
        font-size: 15px;
        color: $color-black;
        font-style: normal;
        text-transform: uppercase;
        width: auto;
        font-family: $font--subheading;
        display: inline;
      }
      .pr-label-control.checked {
        span {
          float: none;
          color: $color-white;
        }
      }
      .input-add-tag {
        .pr-btn-add-tag-submit {
          padding: 5px 5px;
          height: 26px;
        }
      }
      label span::first-letter {
        text-transform: capitalize;
      }
      input,
      textarea,
      select,
      btn-group {
        @include font--text;
        outline: 0;
        max-width: 100%;
        border-radius: 0.25em;
        box-shadow: none;
        -webkit-box-shadow: none;
        &:focus {
          border: 1px solid $color-black;
        }
      }
      input#id-input {
        @include breakpoint(($small-down) (orientation portrait)) {
          width: 220px !important;
        }
      }
      .pr-war {
        max-width: 1024px;
      }
      #pr-war-form {
        .form-group {
          margin-bottom: 25px;
          .pr-control-label {
            @include breakpoint($small-down) {
              width: 100% !important;
            }
          }
          input::placeholder,
          textarea::placeholder {
            @include font--text;
            font-size: 15px;
            line-height: 1.2em;
            text-transform: initial;
          }
          #pr-bottomline {
            .pr-control-label {
              width: 100% !important;
            }
            .btn-group-2-count {
              @include font--text;
              @include breakpoint($small-down) {
                width: 100%;
              }
            }
          }
        }
        .form-group.pr-has-error {
          input.form-control {
            border-color: $color-red;
          }
          label.pr-control-label span {
            color: $color-red;
          }
        }
      }
      .pr-footer {
        .pr-subscript,
        a {
          font-family: $font--subheading;
          color: $color-black;
          font-size: 15px;
          padding-bottom: 30px;
        }
        a {
          text-transform: uppercase;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .pr-btn {
        &.active {
          background-color: $dark-gray;
        }
      }
    }
  }
  .site-footer {
    float: left;
    width: 100%;
  }
}

#pr-write {
  .p-w-r {
    .pr-table-cell:nth-child(2) {
      @include breakpoint($small-down) {
        display: block;
      }
      h3 {
        @include breakpoint($small-down) {
          text-align: center;
        }
      }
    }
    .thank-you-page {
      .title {
        font-family: $font--heading-main;
        text-transform: uppercase;
        font-weight: normal;
      }
      .subtitle {
        font-family: $font--text;
        text-transform: initial;
      }
      .title,
      .subtitle,
      h5 {
        padding: 3px 0;
        margin: 10px 0;
        color: $color-black;
      }
      .pr-rating-date {
        @include font--subheading;
        font-weight: normal;
        color: $color-black;
        text-transform: initial;
      }
      .header {
        margin-top: 0;
      }
    }
    .form-group {
      .pr-btn-default {
        span:after {
          color: $color-black;
        }
      }
      &.pr-media_image-form-group,
      &.pr-media_videourl-form-group {
        div {
          &.pr-file-input-btn {
            @include breakpoint($portrait-up) {
              font-weight: normal;
              border: 2px solid $color-black;
              font-family: $font--heading-main;
            }
          }
        }
        label {
          color: $color-black;
          background-color: $color-white;
          border: 2px solid $color-black;
          @include breakpoint($portrait-up) {
            border: none;
            margin: 0;
            cursor: initial;
            font-weight: normal;
            font-family: $font--heading-main;
            &.pr-btn-fileinput {
              cursor: pointer;
              text-transform: uppercase;
            }
          }
          @include breakpoint($small-down) {
            width: 100% !important;
          }
          &:hover {
            background-color: $color-white;
            border: 2px solid $color-black;
          }
          span {
            color: $color-black;
            float: none;
          }
        }
        .pr-icon-delete {
          span {
            float: none;
          }
          fill: $color-black;
        }
        .form-group {
          input {
            display: none;
          }
        }
        .pr-media-preview {
          .pr-loading-indicator {
            margin: 8%;
          }
        }
      }
    }
    .pr-footer {
      .pr-accessible-btn {
        font-size: 14px;
        font-weight: normal;
        padding: 9px 15px;
        border: 2px solid $color-black;
        color: $color-black;
        background: $color-white;
        font-family: $font--heading-main;
      }
      a {
        font-size: 12px;
        text-decoration: underline;
      }
    }
    .pr-btn-danger {
      background-color: $color-white;
      border: 1px solid $color-black;
      padding: 4px 10px;
      &:hover {
        background-color: $color-white;
        border: 1px solid $color-black;
      }
    }
    .pr-alert-container {
      float: left;
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      &.pr-rd-display-tablet,
      &.pr-rd-display-desktop {
        .pr-rd-side-content-block {
          right: -36%;
          top: -45px;
        }
      }
      .pr-rd-footer {
        .pr-rd-bottomline {
          margin-bottom: 10px;
        }
        .pr-modal-shown {
          overflow: scroll;
        }
        .pr-modal-container {
          z-index: 9000;
        }
      }
      &.pr-rd-display-desktop,
      &.pr-rd-display-tablet {
        .pr-rd-content-block {
          &.pr-accordion {
            margin-bottom: 6px;
          }
        }
      }
      .pr-flag-review-btn {
        &.pr-flag-review-btn-submit {
          @include pr-button-display();
        }
        &.pr-flag-review-btn-cancel {
          @include pr-button-display();
          background-color: $medium-gray;
          border-color: $medium-gray;
          &:hover {
            border-color: $medium-gray;
          }
        }
      }
      .pr-rd-def-list {
        dt,
        dd {
          height: 30px;
        }
      }
      .pr-flag-icon {
        margin-top: -4px;
        float: left;
      }
      .pr-flag-review-thankyou {
        display: inline;
        @include breakpoint($small-down) {
          width: 52%;
          display: inline-block;
        }
      }
      .pr-rd-content-block {
        &.pr-accordion {
          margin-bottom: 6px;
        }
      }
    }
    .pr-review-snapshot-snippets {
      @include pr-background-image();
    }
    header.pr-review-snapshot-header {
      .pr-rating-stars {
        padding-bottom: 0;
        background-color: $light-gray;
        @include breakpoint($large-down) {
          background-color: $color-white;
        }
      }
    }
    #pr-image-display {
      display: none;
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-minimal {
        .pr-snippet-read-and-write {
          @include breakpoint($small-down) {
            margin-left: 0;
            .pr-snippet-review-count {
              display: inline-block;
              border-right: none;
              margin-right: 3px;
              padding-right: 0;
            }
          }
        }
      }
      &.pr-snippet-compact {
        .pr-snippet-read-and-write a {
          float: none;
        }
      }
      .pr-snippet-read-and-write {
        a.pr-snippet-write-review-link {
          display: none;
          padding-left: 15px;
          @include breakpoint($small-down) {
            padding-left: 5px;
          }
        }
        span.pr-snippet-review-count {
          color: $color-black;
        }
      }
    }
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-inline.pr-snippet-minimal {
        .pr-snippet-stars-reco-reco {
          @include breakpoint($small-down) {
            display: none;
          }
        }
      }
    }
  }
}

.product-full__details {
  .p-w-r {
    & :focus {
      outline: none;
      box-shadow: none;
    }
    .pr-snippet {
      .pr-snippet-stars-png {
        @include pr-large-background-image();
      }
    }
    .pr-category-snippet__item {
      @include breakpoint($small-down) {
        display: block;
      }
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        span {
          @include breakpoint($small-down) {
            padding: 0 8px;
          }
        }
      }
    }
  }
  .pr-category-snippet__total {
    padding-top: 4px;
    @include breakpoint($small-down) {
      text-align: center;
    }
  }
  .product-full__size--shaded {
    max-width: 67%;
    @include breakpoint($landscape-up) {
      max-width: 72%;
      padding: 20px 0 0 19px;
    }
  }
}

.spp_product__review-snippet {
  .p-w-r {
    .pr-snippet {
      .pr-snippet-stars-png {
        @include pr-background-image();
      }
    }
  }
}

#pr-flag-reviews {
  input[type='radio'] {
    left: auto;
  }
  .pr-control-label {
    @include pr-label();
  }
}
/* PWR Sign in form */
#pwr_signin {
  @include breakpoint($small-down) {
    padding: 0;
  }
  .pwr_cancel {
    margin-right: 15px;
    background: $color-white;
    border-color: $color-black;
    color: $color-black;
    &:hover {
      background: $color-black;
      border-color: $color-white;
      color: $color-white;
    }
  }
  .pr_label {
    font-family: $font--heading-main;
    font-size: 15px;
    margin-bottom: 5px;
    display: block;
    text-transform: uppercase;
  }
  .pwr_select {
    span {
      display: block;
      margin-bottom: 5px;
      input[type='radio'] {
        vertical-align: sub;
      }
    }
  }
  .pwr_email {
    margin-bottom: 0.5em;
  }
  &.sign-in-page {
    .signin-block {
      @include breakpoint($small-down) {
        margin-top: 15px;
      }
      .error {
        color: $error-color;
      }
    }
  }
  .signin-block__footer {
    input[type='reset'] {
      border-radius: 0;
    }
  }
}
/*
Sytlings for reviews header with snippet with extended features, histogram, pros, search and filters.
class .featured_header is applied only for the reviews that has the above features
*/

.spp_customer_reviews {
  &.featured_header {
    padding: 15px 0;
    border-top: 1px solid $color-black;
    clear: both;
    @include breakpoint($medium-landscape-only) {
      padding: 30px 5%;
    }
    @include breakpoint($landscape-up) {
      padding: 30px 10%;
      border: none;
    }
    .spp_reviews {
      .spp_reviews_heading {
        font-size: 35px;
        text-align: center;
        margin-bottom: 15px;
        margin-left: 0;
      }
    }
    #pr-reviewdisplay {
      .p-w-r {
        margin: 0;
        .pr-review-snapshot {
          border-bottom: 1px solid $medium-gray;
          margin: 0;
          @include breakpoint($landscape-up) {
            border-top: 1px solid $medium-gray;
          }
          &-block {
            border: none;
            min-height: 225px;
            padding: 20px 0 0;
            @include breakpoint($medium-portrait-only) {
              float: none;
              width: 80%;
              margin: 0 auto;
            }
            &-headline,
            &.pr-review-snapshot-block-cons {
              display: none;
            }
            &-histogram {
              display: block;
              width: 100%;
              @include breakpoint($landscape-up) {
                width: 400px;
              }
              .pr-ratings-histogram {
                &-bar,
                &-barValue {
                  height: 27px;
                  border-radius: 0;
                }
                &-barValue {
                  background-color: $color-black;
                }
                .pr-histogram {
                  &-label {
                    text-transform: uppercase;
                    color: $color-black;
                    font-size: 20px;
                    font-family: $font--heading-main;
                    @include breakpoint($medium-landscape-only) {
                      font-size: 17px;
                    }
                  }
                  &-stars {
                    line-height: 40px;
                  }
                  &-count {
                    color: $color-black;
                  }
                }
              }
            }
            &-pros {
              display: none;
            }
            &-container {
              margin-top: 40px;
              @include breakpoint($landscape-up) {
                margin-top: 0;
              }
            }
          }
          &-header {
            width: 100%;
            text-align: center;
            background-color: $color-white;
            @include breakpoint($landscape-up) {
              width: 30%;
              float: right;
            }
          }
          &-snippets {
            @include pr-large-background-image(35px, 35px, 0 5px 0 0);
            .pr-snippet {
              top: 0;
              padding-left: 0;
              &-rating-decimal {
                border: none;
                display: inline-block;
                font-size: 26px;
                color: $color-black;
                font-weight: normal;
                font-family: $font--heading-main;
                &:after {
                  content: '/5';
                  color: $medium-gray;
                }
              }
              &-stars {
                display: inline-block;
                .pr-rating-stars {
                  text-align: center;
                  background-color: $color-white;
                }
                &-reco-reco,
                &-reco-stars {
                  width: 100%;
                }
                &-container {
                  margin: 0 auto;
                }
              }
              &-reco-to-friend {
                display: block;
                text-align: center;
                margin: 10px auto;
                line-height: 25px;
                max-width: 320px;
                &-percent {
                  span[aria-hidden='true'] {
                    display: none !important;
                  }
                }
                .pr-reco {
                  color: $color-black;
                  &-value {
                    font-family: $font--heading-main;
                    color: $color-black;
                    line-height: 25px;
                    font-size: 20px;
                    font-weight: normal;
                    @include breakpoint($medium-landscape-only) {
                      font-size: 17px;
                    }
                  }
                }
              }
              &-read-and-write {
                top: 150px;
                width: 100%;
                margin: 0;
                .pr-snippet-review-count {
                  &::before {
                    content: '(';
                  }
                  &::after {
                    content: ')';
                  }
                }
                a {
                  background-color: $color-black;
                  bottom: -80px;
                  color: $color-white;
                  font-family: $font--heading-main;
                  font-weight: normal;  
                  display: block;
                  margin: 0 auto;
                  padding: 10px;                
                  position: absolute;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;   
                  @include breakpoint($mobile-portrait-down) {
                    bottom: -115px;
                  }              
                  @include breakpoint($landscape-up) {
                    bottom: -110px;
                  }
                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }
          }
        }
        #pr-review-display {
          .pr-rd-main-header {
            background: $color-white;
            padding: 0;
            margin: 5px 0;
            position: relative;
            @include breakpoint($landscape-up) {
              padding: 10px;
            }
            &-search {
              width: 100%;
              &-sort {
                top: 0;
                right: 0;
                padding-top: 0;
                @include breakpoint($landscape-up) {
                  width: 40%;
                  position: absolute;
                  padding-top: 10px;
                }
                .pr-rd-search-reviews {
                  &-input {
                    width: 90%;
                    margin: 0 auto;
                    @include breakpoint($medium-up) {
                      width: 75%;
                    }
                    @include breakpoint($medium-portrait-only) {
                      width: 60%;
                    }
                    input {
                      width: 87% !important;
                      border: 1px solid $color-black;
                    }
                  }
                  &-icon-button {
                    background-color: $color-black;
                    width: 10%;
                    .pr-search-icon {
                      width: 18px;
                      svg {
                        display: none;
                      }
                      &:after {
                        color: $color-white;
                        font-size: 17px;
                        font-family: $font--heading-main;
                        font-weight: normal;
                      }
                    }
                  }
                  &-clear-button {
                    @include breakpoint($medium-portrait-only) {
                      margin-right: 20px;
                    }
                    .pr-cross-icon__line {
                      stroke: $color-black;
                    }
                  }
                }
                .pr-rd-review-header-sorts {
                  width: 100%;
                  text-align: center;
                  padding-left: 15px;
                  .pr-rd-sort {
                    border: none;
                    font-size: 17px;
                    width: 125px;
                    font-weight: normal;
                    font-family: $font--heading-main;
                    min-width: 100px;
                    optgroup {
                      font-weight: normal;
                    }
                    &-group {
                      width: 220px;
                      margin: 0 auto;
                      @include breakpoint($landscape-up) {
                        width: auto;
                        margin: 0;
                      }
                      &:before {
                        content: attr(data-before-content);
                        float: left;
                        padding-top: 10px;
                        font-family: $font--text;
                      }
                    }
                  }
                }
              }
            }
            .pr-rd-review-header-contents {
              background-color: $color-white;
              padding: 0;
              @include breakpoint($landscape-up) {
                margin-top: 40px;
                padding: 10px 0;
              }
              &:before {
                margin: 0 10px 0 0;
                content: attr(data-before-content);
                display: inline-block;
              }
              .pr-multiselect {
                &-button {
                  padding: 8px 8px 8px 0;
                  min-width: 10px;
                  border: none;
                  margin: 5px 10px 5px 0;
                  @include breakpoint($landscape-up) {
                    margin-right: 20px;
                  }
                  .pr-multiselect-count {
                    background-color: $light-gray;
                    font-size: 12px;
                  }
                  span {
                    font-size: 17px;
                    text-transform: uppercase;
                    color: $color-black;
                    font-family: $font--heading-main;
                    font-weight: normal;
                    &.pr-caret-icon {
                      @include icon('triangle-left', after);
                      font-size: 8px;
                      height: 10px;
                      padding-left: 2px;
                      position: static;
                      width: 10px;
                      &:after {
                        color: $color-black;
                      }
                      &:before {
                        color: $color-black;
                      }
                      &__line {
                        stroke: $color-black;
                      }
                      svg {
                        display: none;
                      }
                    }
                  }
                  &-agerange {
                    span {
                      font-family: $font--heading-main;
                      font-weight: normal;
                      &.pr-multiselect-count,
                      &.pr-caret-icon {
                        &:before {
                          content: '';
                        }
                      }
                    }
                    .pr-multiselect-options {
                      span:before {
                        content: '';
                      }
                    }
                  }
                }
                &-options {
                  min-width: 170px;
                }
              }
            }
          }
          .pr-rd-footer {
            .pr-rd-helpful-text {
              display: none;
            }
            .pr-rd-helpful-action-btn-group {
              margin: 0 5px 0 0;
              .pr-helpful-btn {
                .pr-helpful-count {
                  font-weight: bold;
                }
              }
            }
          }
          .pr-rd-side-content-block {
            @include breakpoint($landscape-up) {
              border-#{$ldirection}: 2px solid $color-lightest-gray;
              padding-#{$ldirection}: 15px;
            }
            .pr-rd-reviewer-details {
              display: flex;
              flex-direction: column;
              .pr-rd-bold {
                display: none;
              }
              .pr-rd-author-nickname {
                order: 1;
                span {
                  color: $color-black;
                  font-weight: bold;
                }
              }
              .pr-rd-author-submission-date {
                order: 2;
              }
              .pr-rd-author-location {
                order: 3;
                display: none;
              }
            }
          }
          .pr-rd-content-block {
            .pr-accordion-btn {
              span {
                font-weight: bold;
                color: $color-black;
                font-size: 15px;
              }
              span.pr-caret-icon {
                font-size: 8px;
              }
            }
          }
        }
      }
    }
  }
}

#pr-write .pr-email_collection-form-group {
  // Hide email field from write a review.
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
