///
/// @file  _smashbox-base-mobile.scss
///
/// \brief Pull all parts of the theme scss together into one file
///
@import 'style';

$ab-mobile-size-1: (width 300px 359px);
$ab-mobile-size-2: (width 360px 450px);

.smashcash-marketing .smashcash-marketing__content .marketing-panel--benifits .benifit-level.level-one {
  border-bottom: none;
  @include breakpoint($ab-mobile-size-1) {
    overflow: hidden;
    height: 1125px;
  }
  @include breakpoint($ab-mobile-size-2) {
    overflow: hidden;
    height: 1265px;
  }
}

#marketing-matrix {
  margin-top: 60px;
  @include breakpoint($ab-mobile-size-1) {
    transform: scale(0.7);
    -ms-transform: scale(0.7);
    -mos-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 15px 0;
    -ms-transform-origin: 15px 0;
    -mos-transform-origin: 15px 0;
    -webkit-transform-origin: 15px 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
  @include breakpoint($ab-mobile-size-2) {
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -mos-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    transform-origin: 40px 0;
    -ms-transform-origin: 40px 0;
    -mos-transform-origin: 40px 0;
    -webkit-transform-origin: 40px 0;
    image-rendering: -moz-crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
  }
  th.marketing-title {
    &:after {
      display: none;
    }
    .benifit-level--name {
      font-size: 25px;
      margin-bottom: 16px;
      margin-top: 8px;
    }
    img {
      display: none;
    }
  }
}

.smashcash-marketing__content {
  .marketing-panel {
    .sign-in-component__form-loyalty-header {
      display: none;
    }
    .non-enrolled {
      .sign-in-component__loyalty-program {
        .enroll_btn {
          .register_button {
            width: 100%;
          }
        }
      }
    }
  }
}

.fixfixed .site-header {
  position: absolute;
}
