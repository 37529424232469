// Default Variables
$slick-arrow-color: $color-black;
$slick-arrow-hover: $color-black;
$slick-dot-color: $color-white;
$slick-dot-border-color: $color-black;
$slick-dot-color-active: $color-black;
$slick-dot-size: 16px;
// inverted colors
$slick-dot-color--inverted: $color-black;
$slick-dot-border-color--inverted: $color-white;
$slick-dot-color-active--inverted: $color-white;

// default slick
.slick {
  &-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    .product-grid--carousel & {
      padding: 0 45px;
    }
  }
  &-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  &-track,
  &-list {
    @include transform(translate3d(0, 0, 0));
  }
  &-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    &:before,
    &:after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  &-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
    outline: none;
    [dir='rtl'] & {
      float: right;
    }
    &.slick-loading img {
      display: none;
    }
    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }
    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
}

// overriding for SBX with container specificity
.carousel-controls {
  position: absolute;
  height: 0;
  line-height: 0;
  width: 100%;
  display: block;
  top: 50%;
  .product-grid--carousel & {
    top: 32%;
  }
  .hero-carousel-formatter & {
    top: 80%;
  }
  // @todo pull outside container specificity
  .slick-prev,
  .slick-next {
    @include font-smoothing;
    @include appearance(none);
    background: transparent;
    border: none;
    color: $slick-arrow-color;
    cursor: pointer;
    font-size: 0;
    height: 50px;
    line-height: 50px;
    width: 50px;
    padding: 0;
    position: absolute;
    z-index: 2;
    &:hover {
      color: $slick-arrow-hover;
    }
    &:focus {
      outline: none;
    }
    // this is for the icons
    &:before {
      width: 50px;
      height: 50px;
      line-height: 50px;
      font-size: 50px;
    }
    &.slick-disabled {
      cursor: default;
      opacity: 0.4;
      &:hover {
        opacity: 0.4;
      }
    }
  }
  .slick-prev {
    @include icon('caret--left', $text-replace: true);
    left: 10px;
    position: absolute;
    .carousel--small-arrows & {
      left: 10px;
    }
    &:before {
      position: absolute;
      right: auto;
      left: 0;
    }
  }
  // we need to override positioning for 'next' to account for text-replace
  .slick-next {
    @include icon('caret--right', $text-replace: true);
    right: 10px;
    position: absolute;
    &:before {
      position: absolute;
      right: 0;
    }
  }
}

/// DOTS for carousel

.carousel-dots {
  width: 100%;
  height: $slick-dot-size;
  margin-bottom: $slick-dot-size / 2;
  position: relative;
  @include breakpoint($landscape-up) {
    height: 40px;
    margin-top: -40px;
    margin-bottom: 0;
    &.story-carousel-dots {
      margin-top: 0;
    }
    .product-grid--carousel & {
      display: none; // slick dots is false for desktop so we can just hide div to prevent from overlapping elements i.e. quickshop button
    }
  }
  &--basic {
    @include breakpoint($landscape-up) {
      display: none;
    }
  }
}

// Dots
.slick-dots {
  text-align: center;
  width: 100%;
  // cms settings for alignment per breakpoint
  @include breakpoint($landscape-down) {
    .carousel-dots--mobile-left & {
      text-align: left;
    }
    .carousel-dots--mobile-right & {
      text-align: right;
    }
  }
  @include breakpoint($landscape-up) {
    .carousel-dots--pc-left & {
      text-align: left;
      margin-left: 20px;
    }
    .carousel-dots--pc-right & {
      text-align: right;
      margin-right: 20px;
    }
  }
  li {
    position: relative;
    display: inline-block;
    height: $slick-dot-size;
    width: $slick-dot-size;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      background: transparent;
      display: block;
      height: $slick-dot-size;
      width: $slick-dot-size;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover,
      &:focus,
      &:active {
        outline: none;
        &:before {
          opacity: 1;
        }
      }
      &:before {
        @include circle($slick-dot-size, $slick-dot-color);
        background: $slick-dot-color;
        border: $slick-dot-border-color solid 2px;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: $slick-dot-size;
        height: $slick-dot-size;
        //font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: $slick-dot-size;
        text-align: center;
        color: $slick-dot-color;
        opacity: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        .text--inverted & {
          border: $slick-dot-border-color--inverted solid 2px;
          color: $slick-dot-color--inverted;
        }
        @include breakpoint($landscape-down) {
          .carousel-dots--inverted-mobile & {
            border: $slick-dot-border-color--inverted solid 2px;
            color: $slick-dot-color--inverted;
          }
        }
        @include breakpoint($landscape-up) {
          .carousel-dots--inverted-pc & {
            border: $slick-dot-border-color--inverted solid 2px;
            color: $slick-dot-color--inverted;
          }
        }
      }
    }
    &.slick-active button:before {
      background: $slick-dot-color-active;
      color: $slick-dot-color-active;
      opacity: 1;
      .text--inverted & {
        background: $slick-dot-color-active--inverted;
        color: $slick-dot-color-active--inverted;
      }
      @include breakpoint($landscape-down) {
        .carousel-dots--inverted-mobile & {
          border: $slick-dot-border-color--inverted solid 2px;
          color: $slick-dot-color--inverted;
        }
      }
      @include breakpoint($landscape-up) {
        .carousel-dots--inverted-pc & {
          border: $slick-dot-border-color--inverted solid 2px;
          color: $slick-dot-color--inverted;
        }
      }
    }
  }
}
