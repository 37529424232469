///
/// collapsible-menu
/// A content block that becomes click-expandable with a title
///
.collapsible-menu {
  a {
    text-decoration: none;
  }
}

.collapsible-menu-trigger {
  @include icon('caret--right', after);
  position: relative;
  cursor: pointer;
  &:after {
    @include transform(translateY(-50%));
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 1em;
  }
  .collapsible-menu__parent--expanded & {
    @include icon('caret--down', after);
  }
}

.collapsible-sub-menu {
  display: none;
  .collapsible-menu__parent--expanded & {
    display: block;
  }
}
// static link inside expando-menu
.collapsible-menu__static {
  > a {
    display: block;
    &:hover {
      text-decoration: none;
    }
  }
}
