///
/// @file template_api/endeca/_esearch_forms.scss
///
/// \brief Search Block for site header area; form on search page
///
/// @todo test in header and redo styles
/// @setup new brand - these styles can be customized per design
///

// @todo check main variables, see if we need them here
// @todo style bem classes for page form not this generic wrapper
.search-form {
  width: 100%;
  &__form-fields {
    max-width: 768px;
    margin: 0 auto;
    position: relative;
  }
  input[type='text'] {
    @include font--text;
    @include input-placeholder {
      vertical-align: baseline;
      color: $color-text-gray;
    }
    padding: 10px 15px 4px;
    font-size: 24px;
    width: 100%;
    height: auto;
    display: block;
    border: none;
    border-bottom: $border;
    @include breakpoint($portrait-up) {
      font-size: 30px;
    }
    @include breakpoint($landscape-up) {
      font-size: 36px;
      width: 90%;
      padding: 0;
    }
    &:focus {
      outline: 0;
    }
  }
  .search-form__submit {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      opacity: 0;
      position: absolute;
      top: -8px;
      right: 0;
      z-index: 1;
      height: 40px;
    }
  }
  .icon--search {
    display: none;
    @include breakpoint($landscape-up) {
      display: block;
      position: absolute;
      font-size: 40px;
      top: -8px;
      right: 0;
    }
  }
  &__results {
    @include breakpoint($landscape-up) {
      max-width: $pc-search-width; // width of text field
    }
  }
  &--results {
    margin: 0 auto;
    position: relative;
    // account for extra padding when on active search page in gnav block
    .search-page--loaded .esearch-nav & {
      margin-bottom: -1em;
    }
    .search-form__results-count {
      display: none;
      position: absolute;
      border-top: solid 1px $color-black;
      border-bottom: solid 1px $color-black;
      right: auto;
      width: 100%;
      text-align: center;
      height: 35px;
      line-height: 35px;
      padding: 0 12px;
      @include breakpoint($landscape-up) {
        border: none;
        right: 169px;
        width: auto;
        text-align: left;
      }
    }
  }
}
