/* Chat Window UI */
.livechat-window {
  overflow: hidden;
  color: $color-black;
  .vba-top {
    padding: 10px 25px;
    &__logo {
      height: 21px;
      .livechat_logo {
        line-height: 0;
        font-size: 36px;
      }
    }
    &__btn-container {
      position: absolute;
      top: 22px;
      right: 2px;
      div {
        float: left;
      }
    }
    &__link {
      cursor: pointer;
      text-transform: uppercase;
      text-decoration: underline;
    }
    &__close {
      margin: 0 100px 0 15px;
    }
  }
  #iframe-container {
    margin-left: -9999px;
  }
  .vba-left {
    float: left;
    position: relative;
    width: 60%;
  }
  .vba-top-left {
    border: 1px solid $color-black;
    height: 475px;
    overflow-x: hidden;
    width: 100%;
    &__agent-typing {
      font-style: italic;
      padding: 3px 25px;
      font-size: 14px;
    }
    #chatContainer {
      display: none;
    }
    #requestChat {
      height: auto;
      float: right;
      margin-right: 20px;
    }
    #chatLines {
      margin-bottom: 12px;
      position: relative;
      overflow: hidden;
      .no-icon-line {
        font-size: 14px;
        padding: 10px 15px;
        .line-by {
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      .icon {
        background: url('/media/images/livechat/artist_default_small.png');
        float: left;
        height: 34px;
        margin-right: 15px;
        overflow: hidden;
        width: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .rich-line-content {
      width: 100%;
      clear: both;
      padding: 25px;
      .txt-line {
        font-size: 14px;
        width: 295px;
        float: left;
        word-wrap: break-word;
      }
      .icon-agent,
      .icon-system {
        background-color: $color-black;
      }
      .txt-line .line-by {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  .vba-bottom-left {
    border-right: 1px solid $color-black;
    height: 114px;
    padding-top: 20px;
    width: 100%;
    &__input {
      width: 65%;
      float: left;
      margin-left: 25px;
      height: 40px;
      line-height: 1;
      padding: 10px;
      border: $border;
    }
    &__send {
      @include button();
      cursor: pointer;
      float: left;
      height: 40px;
      margin-left: 25px;
      padding: 6px 13px;
    }
  }
  .vba-right {
    float: right;
    position: relative;
    width: 40%;
  }
  .vba-top-right {
    border: 1px solid $color-black;
    border-left: none;
    float: right;
    height: 335px;
    line-height: 1.5em;
    overflow: hidden;
    width: 100%;
    &__photo-header {
      // @todo use heading default?
      @include font--subheading-alt;
      font-size: 20px;
      padding-top: 10px;
      margin: 10px 0;
      text-align: center;
      text-transform: uppercase;
    }
    &__photo-upload {
      text-align: center;
    }
    &__silhouette {
      height: 164px;
      margin: 15px auto 0;
      width: 163px;
      img {
        width: 100%;
      }
    }
    &__login-text {
      width: 300px;
      font-size: 14px;
      line-height: 20px;
      margin: 20px auto 0;
      text-align: center;
    }
    &__upload {
      background: none;
      color: $color-black;
      font-family: $font--text;
      font-size: 16px;
      margin: 8px auto 0;
      text-decoration: underline;
      width: 75%;
    }
  }
  .vba-bottom-right {
    border: none;
    border-right: 1px solid $color-black;
    float: right;
    height: 253px;
    width: 100%;
    overflow-y: auto;
    word-break: keep-all;
    padding-right: 10px;
    .btn-arrow {
      &--left,
      &--right {
        cursor: pointer;
        position: absolute;
        top: 40%;
      }
      &--left {
        left: 5px;
      }
      &--right {
        right: 5px;
      }
    }
  }
  .vba-bottom-right__consultant {
    &-header {
      // @todo use defaulsts?
      @include font--subheading-alt;
      font-size: 24px;
      margin: 15px 0;
      text-align: center;
    }
    &-msg,
    &-fave,
    &-location {
      font-size: 14px;
      padding-left: 35px;
      clear: left;
      margin-bottom: 10px;
    }
    &-title {
      text-transform: uppercase;
      font-weight: bold;
      &::after {
        content: ':';
      }
    }
    &-name {
      // @todo use heading?
      @include font--subheading-alt;
      font-size: 18px;
      margin-bottom: 12px;
      font-weight: bold;
    }
    &-silhouette {
      width: 60px;
      height: 60px;
      float: left;
      background-color: none;
      margin: 0 25px 20px 35px;
      text-align: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .btn-arrow--right {
      display: none;
    }
  }
  .vba-bottom-right__recommended {
    display: none;
    &-header {
      // @todo use heading?
      @include font--subheading-alt;
      font-size: 24px;
      margin: 15px 0;
      text-align: center;
    }
    &-content {
      float: left;
      height: 300px;
      width: 350px;
      overflow: hidden;
      margin: 0 29px;
    }
    &-mask {
      min-width: 2000px;
      position: relative;
      left: 0;
    }
    .carousel-content-panel {
      float: left;
      width: 350px;
    }
    .img-col {
      float: left;
      margin-right: 15px;
      text-align: center;
      width: 79px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .detail-col {
      float: left;
      width: 185px;
    }
    .prod-title {
      @include font--subheading-alt;
      font-size: 20px;
      line-height: 1;
      margin-bottom: 10px;
      overflow: hidden;
      width: auto;
    }
    .prod-units {
      font-size: 12px;
      margin-bottom: 10px;
      overflow: hidden;
      width: auto;
    }
    .shade-container {
      float: left;
      height: 15px;
      overflow: hidden;
      width: auto;
    }
    .shade-shim {
      overflow: hidden;
      float: left;
      border-radius: 15px;
      -moz-border-radius: 15px;
      width: 15px;
      height: 15px;
    }
    .shade-name {
      float: left;
      font-size: 12px;
      margin-left: 5px;
      overflow: hidden;
      width: auto;
    }
    .price-info {
      float: right;
      font-style: italic;
      height: auto;
      width: auto;
      font-size: 14px;
    }
    .add-to-bag-btn-container {
      position: relative;
      padding-top: 50px;
      .btn-addToBag {
        @include button();
        padding: 6px 10px;
        cursor: pointer;
      }
      .icon {
        position: absolute;
        width: 18px;
        height: 15px;
        cursor: pointer;
        margin-top: 15px;
        top: 5px;
        left: 0;
      }
      .msg-addToBag {
        display: none;
        font-size: 12px;
        padding-top: 10px;
      }
    }
  }
}
/* Photo Upload Overlay */
body.lp #cboxOverlay {
  display: none;
  background: none repeat scroll 0 0 gray;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 0.92;
}

.vba-photo-upload {
  padding: 20px;
  &__header {
    @include font--subheading-alt;
    font-size: 28px;
    margin-bottom: 15px;
  }
  &__instructions {
    font-size: 16px;
    p {
      line-height: 20px;
    }
  }
  &__help ul {
    line-height: 20px;
    margin: 0 0 20px 20px;
  }
  &__choose {
    float: left;
    font-size: 14px;
    width: 250px;
  }
  &__btn {
    @include button();
    width: 250px;
    text-align: center;
  }
  #choose-file-btn {
    float: left;
    margin: 0;
  }
  &__save {
    float: right;
  }
  &__chosen {
    float: left;
    margin-top: 20px;
    border-bottom: 1px solid $color-black;
    span {
      width: 150px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;
      text-transform: uppercase;
    }
  }
}
/* Thank You Page UI */
.livechat-thanks {
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  padding: 30px;
  &__container {
    width: 71%;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: $border;
  }
  &__header {
    @include font--subheading-alt;
    font-size: 40px;
    line-height: 1;
  }
  &__sub-header {
    @include font--subheading-alt;
    font-size: 28px;
    line-height: 1;
    margin-bottom: 15px;
  }
  &__instructions {
    font-size: 14px;
    line-height: 20px;
    a {
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
  .new-account {
    width: 71%;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: $border;
    &__fieldset {
      width: 450px;
    }
    &__email,
    &__password {
      margin-bottom: 20px;
      width: 320px;
      input {
        width: 100%;
        height: 40px;
        padding: 10px;
        border: 1px solid $color-black;
      }
    }
    a {
      text-transform: uppercase;
      text-decoration: underline;
    }
    input[type='checkbox'] {
      position: absolute;
      left: -9999px;
      & ~ label {
        @include icon('checkbox', before);
        font-size: 14px;
        .label-content {
          margin-left: 10px;
          display: inline-block;
          width: 93%;
          vertical-align: top;
        }
      }
      &:checked ~ label {
        @include icon('checkbox--checked', before);
      }
    }
    input[type='submit'] {
      @include button();
      margin-top: 20px;
    }
  }
  &__tout {
    height: 195px;
    width: 71%;
    background-color: $border;
  }
}

.menu__list .live_chat:hover {
  cursor: pointer;
  text-decoration: underline;
}
