// @todo styleguide updates - commented out styles are so styleguide can prevail
// @todo remove commented code

.gift-card-order {
  padding: 0 10px;
  @include breakpoint($landscape-up) {
    max-width: 1024px;
    margin: 0 auto;
  }
  @include breakpoint($medium-down) {
    margin-top: -51px;
  }
  &__photo--image {
    @include breakpoint($landscape-up) {
      margin-top: -69px;
    }
  }
  &__header {
    @include vertical-center;
    h1 {
      @include font--heading-display;
      //         font-size: 18px;
      //         text-align: center;
      //         text-transform: uppercase;
      //         letter-spacing: 0px;
      @include breakpoint($landscape-up) {
        //        font-family: $font--subheading-alt;
        font-size: 100px;
        padding: 0 0 0 40px;
        //        text-align: left;
        margin: 0;
        line-height: 87px;
      }
    }
    p {
      @include breakpoint($landscape-up) {
        margin-top: 10px;
        padding-left: 40px;
      }
    }
    .gc_buynow {
      @include breakpoint($landscape-up) {
        margin-left: 40px;
        padding: 10px 30px;
        text-transform: uppercase;
      }
    }
    .gc_disclaimer {
      @include breakpoint($landscape-up) {
        font-size: 12px;
        margin-top: 15px;
      }
    }
  }
  &__photo {
    @include breakpoint($landscape-up) {
      width: 50%;
      &--image {
        background: $color-light-gray url(/media/export/cms/eGiftcard_pc.jpg) no-repeat right;
        background-size: contain;
        background-repeat: no-repeat;
        height: 500px;
      }
    }
    img.pc-hidden {
      width: 100%;
      height: auto;
    }
    .social-icons {
      margin: 10px 0 25px 0;
      text-align: right;
      img {
        width: 75%;
      }
    }
    .gc_purchase_details {
      text-align: center;
      .gc_disclaimer {
        margin-top: 15px;
      }
    }
  }
  &__content {
    @include breakpoint($landscape-up) {
      width: 56%;
      margin: 0 auto;
      padding-top: 45px;
    }
    &--header {
      @include h1;
      //       font-family: $font--subheading-alt;
      //      font-size: 60px;
      margin: 10px 0 5px 0;
      //      text-transform: uppercase;
      text-align: center;
      //      line-height: 52px;
    }
    &--sub--header {
      // reset h2 to load as normal text for mobile. recommend changing parkup to use a p
      @include basic-text-reset;
      //      color: $color-text-gray;
      //      font-family: $font--subheading-alt;
      margin-bottom: 10px;
      text-align: center;
      //      font-size: 12px;
      @include breakpoint($landscape-up) {
        @include h1;
        margin: 0;
        //         font-size: 38px;
        //         font-family: $font--subheading-alt;
        //         letter-spacing: 2px;
        //        margin-bottom: 20px;
        //        text-transform: uppercase;
        //        color: $color-black;
        text-align: center;
      }
    }
    .gift-card-form {
      &__select--mobile {
        @include breakpoint($medium-down) {
          padding-bottom: 12px;
        }
      }
    }
  }
  &__intro {
    //     font-family: $font--text-alt;
    //     font-size: 16px;
    //     line-height: 25px;
    margin-bottom: 15px;
    @include breakpoint($landscape-up) {
      //      line-height: 20px;
      margin-bottom: 0;
      //      font-size: 14px;
    }
  }
  &__form {
    padding: 20px 0;
    #egiftcard.egift_frm {
      input,
      textarea,
      select {
        //         @include input-placeholder {
        //           color: $color-grey;
        //         }
        //        border: 1px solid $color-grey;
        //        margin-top: 15px;
        width: 100%;
        //         padding: 0 10px;
        //         font-size: 12px;
        //         height: 30px;
        //         line-height: 30px;
      }
      // @note - suzi redid this styling against classes not input elements
      // see line 138
      //       input {
      //         @include breakpoint($landscape-up) {
      //           float: left;
      //           height: 45px;
      //           line-height: 45px;
      //           margin: 0 20px 0 0;
      //           width: 46%;
      //         }
      //       }
      textarea {
        //         @include input-placeholder {
        //           text-transform: uppercase;
        //         }
        //        height: 100px;
        & + label {
          margin-bottom: 9px;
        }
        @include breakpoint($landscape-up) {
          width: 96%;
          //          height: 75px;
        }
      }
    }
    .gift-card-form__email,
    .gift-card-form__verify--email {
      @include breakpoint($landscape-up) {
        float: left;
        margin-right: 20px;
        width: 46%;
      }
      label:before {
        @include breakpoint($landscape-up) {
          margin-top: 1px;
        }
      }
    }
    .gift-card-order__terms--link {
      @include breakpoint($landscape-up) {
        margin-top: 25px;
      }
    }
    //    .error {
    //      font-size: 12px;
    //    }
  }
  .gift-card-form {
    //    &__submit input#submit_giftcard {
    //       font-family: $font--subheading-alt;
    //       font-size: 18px;
    //       padding: 0;
    //       @include breakpoint($landscape-up) {
    //         height: 38px;
    //         width: 85%;
    //         line-height: 30px;
    //         padding: 0 35px;
    //       }
    //       &:hover {
    //         background: $color-white;
    //         color: $color-black;
    //       }
    //       &:hover {
    //         background: $color-white;
    //         color: $color-black;
    //       }
    //    }
    &__msgcount {
      //       font-family: $font--link;
      //       font-size: 14px;
      margin-top: 5px;
      //      text-transform: uppercase;
      padding-left: 10px;
      //       @include breakpoint($landscape-up) {
      //         font-size: 14px;
      //         padding-left: 10px;
      //       }
    }
    //     &__select--mobile {
    //       .select-box__label {
    //         border: $border;
    //         &::after {
    //           border-left: $border;
    //         }
    //       }
    //     }
    &__from--details {
      @include breakpoint($landscape-up) {
      }
    }
    &__errors {
      padding-bottom: 13px;
    }
  }
  &__information {
    padding: 20px 0 6px 0;
    //     font-size: 12px;
    //     line-height: 20px;
    //     a {
    //       text-decoration: underline;
    //     }
    @include breakpoint($landscape-up) {
      //       font-family: $font--link;
      //       font-size: 14px;
      text-align: left;
      padding: 12px 0;
      text-transform: inherit;
      //       a {
      //         text-decoration: none;
      //       }
    }
  }
  &__terms {
    &--link {
      //        font-size: 12px;
      margin-top: 15px;
      //         a {
      //           text-decoration: underline;
      //         }
      @include breakpoint($landscape-up) {
        //        font-family: $font--link;
        margin-top: 30px;
        //         a {
        //           text-decoration: underline;
        //         }
      }
    }
  }
  &__select--value--container {
    @include breakpoint($landscape-up) {
      margin-top: 15px;
      //       .select-box__wrapper,
      //       .select-box__label,
      //       .select-box,
      //       .select-box__options {
      //         min-width: inherit;
      //         width: 100%;
      //       }
      .gift-card-form {
        &__select--value {
          //          width: 44%;
          float: left;
          .select-box {
            &__label {
              @include breakpoint($landscape-up) {
                padding: 0 6.1em 0 3.1em;
                width: 260px;
              }
            }
          }
        }
        &__submit {
          float: left;
          margin-left: 24px;
          .button {
            letter-spacing: 0px;
            padding: 10px 17px 10px 17px;
          }
        }
      }
      .select-box {
        &__options.open {
          width: 221px;
        }
      }
    }
    @include breakpoint($medium-portrait-only) {
      // iPad portrait
      margin-top: 15px;
      .gift-card-form {
        &__select--value {
          float: left;
          .select-box {
            &__label {
              padding: 0 6.1em 0 3.1em;
              width: 260px;
            }
          }
        }
        &__submit {
          float: left;
          margin-left: 24px;
          .button {
            letter-spacing: 0px;
            padding: 10px 17px 10px 17px;
          }
        }
      }
      .select-box {
        &__options.open {
          width: 221px;
        }
      }
    }
    @include breakpoint($medium-down) {
      .gift-card-form {
        &__submit {
          margin-top: 15px;
          .button {
            letter-spacing: 3px;
            padding: 8px 0px 8px 0px;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
  &__accordion {
    border-bottom: $border;
    margin-bottom: 15px;
    margin-top: 40px;
    @include breakpoint($landscape-up) {
      max-width: 600px;
      margin: 40px auto 80px;
      width: 95%;
    }
    @include breakpoint($medium-down) {
      margin-bottom: 80px;
    }
    h2.giftcards-container__header {
      width: 100%;
      span.accordion-sub-header {
        font-family: $font--subheading-alt;
        text-transform: uppercase;
        font-weight: 100;
        font-size: 25px;
      }
    }
    .giftcards-container {
      &__show--content {
        display: none;
        ul {
          &.error_messages {
            padding-left: 0;
            list-style: none;
            margin-bottom: 15px;
            font-size: 12px;
          }
          li {
            list-style: disc;
          }
        }
      }
      &__content {
        position: relative;
        border-top: $border;
        h2 {
          // ok to have custom heights because this is a toggle trigger
          //           font-family: $font--subheading-alt;
          //           font-size: 20px;
          height: 73px;
          line-height: 70px;
          margin: 0;
          padding-left: 20px;
          //          text-transform: uppercase;
          @include breakpoint($landscape-up) {
            cursor: pointer;
          }
          @include breakpoint($medium-down) {
            padding-left: 0px;
          }
        }
        .icon-right {
          @include icon('caret--right', after);
          &::after {
            font-size: 12px;
            font-weight: 600;
            line-height: 3.5;
            position: absolute;
            right: 20px;
            top: 18px;
          }
        }
        .icon--caret--up {
          &:before {
            font-size: 12px;
            font-weight: 600;
            line-height: 3.5;
            position: absolute;
            right: 20px;
            top: 18px;
          }
        }
        .icon-down {
          @include icon('caret--down', after);
          &::after {
            font-size: 12px;
            font-weight: 600;
            line-height: 3.5;
            position: absolute;
            right: 19px;
            top: 18px;
          }
        }
      }
      &__balance--gcnumber {
        label:before {
          @include breakpoint($landscape-up) {
            margin-top: 1px;
          }
        }
      }
      &__balance--gcpin {
        label:before {
          @include breakpoint($landscape-up) {
            margin-top: 1px;
          }
        }
        @include breakpoint($landscape-up) {
          margin-bottom: 24px;
        }
      }
      &__balance--submit {
        @include breakpoint($landscape-up) {
          letter-spacing: 1px;
          margin-bottom: 7px;
          padding: 8px 25px 8px 25px;
        }
      }
      &__balance {
        &--subheader {
          //          font-family: $font--subheading-alt;
          margin: 15px 0;
          //          text-transform: uppercase;
        }
        // new style to pad the conteiner vs margins on the form elements that broke adpl
        &--content {
          padding: 0px 20px 20px;
          @include breakpoint($medium-down) {
            padding: 0px;
            input[type='submit'] {
              letter-spacing: 3px;
              margin-bottom: 27px;
              padding: 8px 0px 8px 0px;
              text-align: center;
              width: 100%;
            }
          }
        }
        &--instruction {
          //           font-family: $font--text-alt;
          //           line-height: 20px;
          margin-bottom: 15px;
          //          font-size: 14px;
        }
        &--gcnumber {
          margin-top: 10px;
          @include breakpoint($landscape-up) {
            float: left;
            margin-top: 0;
            margin-right: 15px;
          }
        }
        //         &--submit {
        //           font-family: $font--subheading-alt;
        //           font-size: 18px;
        //           letter-spacing: 2px;
        //           margin-bottom: 27px;
        //           padding: 0 25px 0 25px;
        //           width: 100%;
        //          @include breakpoint($landscape-up) {
        //              width: auto;
        //          }
        //           &:hover {
        //              background: $color-white;
        //              color: $color-black;
        //              border: 2px solid $color-black;
        //              line-height: 18px;
        //           }
        //         }
        .form_element {
          input {
            //             @include input-placeholder {
            //               color: $color-black;
            //             }
            //             border: $border;
            width: 100%;
            //             padding: 7px 10px 5px;
            //            margin-bottom: 15px;
            @include breakpoint($landscape-up) {
              width: 244px;
            }
          }
        }
        #remaining-balance {
          margin-bottom: 15px;
          //           font-family: $font--subheading-alt;
          //           text-transform: uppercase;
        }
      }
      &__about {
        &--content {
          padding: 0 20px;
          //           a {
          //             text-decoration: underline;
          //           }
        }
        &--subheader {
          //          font-family: $font--subheading-alt;
          margin: 15px 0;
          //          text-transform: uppercase;
        }
        .about_purchasing,
        .about_redeeming {
          //           font-family: $font--text-alt;
          //           line-height: 20px;
        }
        dl {
          margin-bottom: 20px;
          dt {
            //            font-size: 14px;
          }
          dd {
            //            font-size: 12px;
            ul {
              padding-left: 12px;
            }
          }
        }
        h4 {
          color: $color-black;
        }
      }
    }
  }
}
/* Retrieve Page */
#giftcard-buy-header {
  margin: 0 auto 100px;
  max-width: 1280px;
  padding: 20px;
  @include breakpoint($landscape-up) {
    padding-left: 27px;
  }
  .myaccount-hdr {
    border-bottom: $border;
    //    font-family: $font--subheading-alt;
    padding-bottom: 5px;
    //     text-transform: uppercase;
    //     font-size: 22px;
    //     @include breakpoint($landscape-up) {
    //       font-size: 32px;
    //     }
  }
  .egift_data_wrapper {
    //    font-family: $font--subheading-alt;
    margin-bottom: 10px;
    text-transform: uppercase;
    td {
      display: inline-block;
      margin-bottom: 10px;
    }
  }
}
/* End of Retrieve Page */

/* Giftcard overlay */
.sample-giftcard-overlay {
  #cboxClose {
    &::before {
      color: $color-white;
    }
  }
  img {
    width: 100%;
  }
}
