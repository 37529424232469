.ptools {
  @include transition(opacity 200ms ease-out);
  position: fixed;
  width: 50px;
  right: 0;
  top: 0;
  z-index: 99999;
  opacity: 0.85;
  &--expanded {
    opacity: 1;
  }
  &__burger {
    @include transition(margin 200ms ease-out);
    position: absolute;
    right: 0;
    cursor: pointer;
    margin: 4px 4px 0 0;
    height: 10px;
    width: 10px;
    .ptools:hover &,
    .ptools--expanded & {
      margin: 8px 8px 0 0;
      height: 27px;
      width: 27px;
    }
    &__component {
      @include transition(all 200ms ease-out);
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      background: #000;
      border-radius: 2px;
      height: 1px;
      width: 10px;
      &--y {
        top: 3px;
      }
      &--z {
        top: 6px;
      }
      .ptools:hover &,
      .ptools--expanded & {
        height: 2px;
        width: 19px;
        &--y {
          top: 6px;
        }
        &--z {
          top: 12px;
        }
      }
      &--collapse {
        @include transition(all 70ms ease-out);
        background: #4a89dc;
        .ptools:hover &,
        .ptools--expanded & {
          top: 9px;
        }
      }
      &--rotate30 {
        @include transform(rotate(30deg));
        @include transition(all 50ms ease-out);
      }
      &--rotate150 {
        @include transform(rotate(150deg));
        @include transition(all 50ms ease-out);
      }
      &--rotate45 {
        @include transform(rotate(45deg));
        @include transition(all 100ms ease-out);
      }
      &--rotate135 {
        @include transform(rotate(135deg));
        @include transition(all 100ms ease-out);
      }
    }
  }
  &__circle {
    @include transition(all 200ms cubic-bezier(0, 0.995, 0.99, 1));
    border-radius: 50%;
    position: absolute;
    background: #eee;
    opacity: 1;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    .ptools:hover & {
      width: 100px;
      height: 100px;
      top: -50px;
      right: -50px;
    }
    .ptools--expanded &,
    .ptools--expanded:hover & {
      @include transition(all 200ms cubic-bezier(0, 0.995, 0.99, 1));
      width: 1200px;
      height: 1200px;
      top: -560px;
      right: -565px;
    }
  }
  &__menu {
    position: absolute;
    top: 0;
    right: 0;
    .ptools--expanded & {
      height: 568px;
      width: 100vw;
      max-width: 555px;
    }
    &__item {
      @include transition(all 70ms cubic-bezier(0, 0.995, 0.99, 1));
      list-style: none;
      text-align: center;
      // This next bit absolutely positions each item in the list (up to 10) and
      // animates them. Seems brittle.
      position: absolute;
      top: 50px;
      opacity: 0;
      width: 100%;
      font-size: 0px;
      .ptools--expanded & {
        @include transition(all 200ms cubic-bezier(0, 0.995, 0.99, 1));
        font-size: 21px;
        opacity: 1;
        @for $i from 0 through 10 {
          &:nth-of-type(#{$i + 1}) {
            top: 120px + (($i - 1) * 70px);
            transition-delay: (($i - 1) * 0s);
            transition-delay: 0s;
          }
        }
      }
      &__link {
        color: #4a89dc;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 3px;
      }
    }
  }
  &__set-state {
    position: absolute;
    top: 0;
    right: 0;
    min-height: 340px;
    width: 100vw;
    max-width: 555px;
    display: none;
    background-color: #fff;
    .ptools--set-state & {
      display: block;
    }
    &__back {
      cursor: pointer;
      display: block;
      width: 300px;
      margin: -1.4em auto 0;
    }
    form {
      width: 300px;
      text-align: right;
      padding: 30px 0 0;
      margin: 0 auto;
    }
    legend {
      text-align: center;
    }
    .fieldset-legend {
      text-transform: uppercase;
      font-size: 1.2em;
    }
    .form-wrapper {
      padding: 0;
      margin: 0;
      border: none;
      label {
        width: 150px;
        display: block;
        float: left;
      }
    }
    .form-item,
    .form-submit {
      margin: 20px 0 0;
    }
    select {
      width: 150px;
    }
    .fieldset-wrapper {
      text-align: left;
    }
  }
}
