#marketing-matrix {
  text-transform: uppercase;
  border-collapse: collapse;
  width: 100%;
  tr {
    &:last-child {
      td {
        border-bottom: 0;
      }
    }
    td {
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
    th {
      &:first-child {
        border-left: 0;
      }
      &:last-child {
        border-right: 0;
      }
    }
  }
  th {
    border: 1px solid #a6a6a6;
    text-align: center;
    vertical-align: bottom;
    &.marketing-title {
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 25%;
        width: 4px;
        padding: 0;
        margin: 0;
        background-color: white;
        position: absolute;
        #{$ldirection}: 0;
        top: 0px;
      }
      .fierce {
        color: $color-dark-pink;
      }
      .fearless {
        color: $color-dark-red;
      }
      .benifit-level--type {
        margin-top: 20px;
      }
    }
  }
  td {
    text-align: center;
    vertical-align: middle;
    border: 1px solid #a6a6a6;
    padding: 16px;
    font-family: 'Knockout 29 Web', arial, sans-serif;
    font-size: 18px;
    line-height: 18px;
    height: 60px;
    &.row-title {
      font-weight: 100;
      font-size: 20px;
      line-height: 20px;
      font-family: 'Knockout 49 Web', arial, sans-serif;
      text-align: left;
    }
    .sign-up-bonus-extra,
    .bonus-points-text,
    .returns-text,
    .details-text {
      font-size: 18px;
    }
    .fab-points,
    .fierce-points,
    .fearless-points {
      width: 16px;
      height: 16px;
      -moz-border-radius: 9px;
      -webkit-border-radius: 9px;
      border-radius: 9px;
      display: inline-block;
      margin: 4px;
    }
    .fab-points {
      background-color: $color-pink;
    }
    .fierce-points {
      background-color: $color-dark-pink;
    }
    .fearless-points {
      background-color: $color-dark-red;
    }
  }
}

.smashcash-marketing .smashcash-marketing__content .marketing-panel--benifits .benifit-level.level-one {
  width: 100%;
  float: none;
  background: none;
  padding: 0;
  .benifit-level--inner-content {
    border: none;
    padding: 0;
  }
}

.benifit-level.level-two,
.benifit-level.level-three {
  display: none;
}
