/**
 * Generic typeahead
 */
.typeahead-wrapper {
  background: $color-white;
  .typeahead-suggestions__item {
    padding: 4px 0;
  }
  .search-suggestions__item {
    display: block;
    padding: 15px;
    border-bottom: 1px solid $color-black;
    @include breakpoint($landscape-up) {
      padding: 25px 20px;
      text-align: center;
    }
  }
  .product-results {
    padding: 15px;
    @include breakpoint($landscape-up) {
      @include pie-clearfix;
      padding: 20px 20px 0 20px;
      max-width: 1240px;
      margin: 0 auto;
    }
    > div {
      display: none;
      visibility: hidden;
      // hide all but the first 4
      &:nth-child(-n + 4) {
        display: block;
        visibility: visible;
      }
    }
  }
}
// search page header will populate this - hide in the nav
.search-page--loaded .esearch-nav__suggestions-wrapper {
  display: none !important;
}

.esearch-product--typeahead {
  .product-brief__container {
    padding: 0;
  }
  @include breakpoint($landscape-up) {
    float: left;
    width: 23%;
    margin-right: 1.53657%;
    &.last {
      margin-right: 0;
    }
  }
  &--mobile {
    text-align: left;
    margin: 0 0 15px 0;
    .esearch-product__headline {
      font-family: $font--subheading-alt;
      margin: 0 0 3px 0;
      font-size: 22px;
      line-height: 22px;
      a {
        text-decoration: none;
      }
    }
  }
}

/**
 * Search form in page content - special positioning
 * @todo audit this
 */
.site-content {
  .search-form {
    position: relative;
    .typeahead-wrapper {
      position: absolute;
      z-index: 999;
      width: 100%;
      background-color: $color-white;
      //border: $color-black solid 2px;
      //padding: 1em;
    }
  }
}

.esearch-nav {
  &__popular {
    margin: 20px 0 0;
    text-align: center;
    font-family: $font-family-knockout;

    &__item {
      margin: 0 0 10px;
      line-height: normal;

      a {
        font-family: $font--link;
        font-size: $base-font-size;
        text-decoration: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-list {
      margin-top: 13px;
    }
  }
}

.elc__popular-header {
  text-transform: uppercase;
  line-height: normal;
  font-size: 20px;
}
