// @todo styleguide updates - commented out styles are so styleguide can prevail
// @todo remove commented code

/* PRECHAT OVERLAY UI */
.prechat-overlay {
  .prechat-container {
    .icon--close {
      display: none;
    }
    &__header {
      margin: 0 0 15px 0;
      padding-bottom: 15px;
      line-height: 1;
      border-bottom: $border;
    }
    &__instructions,
    &__required {
      margin-bottom: 15px;
      line-height: 1;
    }
    .prechat-form {
      &__name {
        position: relative;
        .prechat-form__input {
          margin-right: 4%;
        }
      }
      &__signout {
        position: absolute;
        top: 42px;
      }
      .form-col-one,
      .form-col-two {
        float: left;
        width: 48%;
      }
      .form-col-two {
        float: right;
      }
      &__input {
        width: 100%;
      }
      &__question {
        clear: both;
        #textarea-your-question {
          width: 100%;
        }
      }
      &__contact-us a {
        text-decoration: underline;
        text-transform: uppercase;
      }
      &__btn-container {
        width: 100%;
        display: inline-block;
      }
      &__order-chat,
      &__beauty-chat {
        width: 50%;
        float: left;
      }
      &__message {
        padding: 0 0 20px;
        margin-top: 5px;
      }
      &__contact-us {
        p {
          margin: 1em 0 0 0;
        }
      }
    }
  }
}
/* STICKY CHAT UI */
.sticky-livechat {
  @include transition(bottom 0.2s ease-in);
  @include animation-duration(1s);
  @include animation-fill-mode(both);
  @include font-smoothing(antialiased);
  cursor: pointer;
  position: fixed;
  z-index: 1000;
  bottom: -46px;
  padding: 8px 12px 15px;
  text-align: center;
  background: $color-black;
  height: auto;
  left: 20px;
  width: 215px;
  border: 1px solid $color-white;
  border-bottom: none;
  text-transform: uppercase;
  &:hover {
    bottom: 0;
  }
  &__link:hover {
    text-decoration: none;
  }
  &__headline {
    margin: 0 0 5px 0;
  }
  &__copy,
  &__cta {
    margin: 0;
  }
  &__cta {
    border-bottom: solid 1px;
  }
  &-content.integrated-prechat {
    display: block;
    margin-top: -38px;
    margin-left: -12px;
    width: 213px;
    background-color: #fff;
    border: 1px solid $color-black;
    color: $color-black;
  }
  .prechat-container {
    padding: 10px;
    div.icon--close {
      text-align: right;
      width: 100%;
      font-size: 20px;
      font-weight: bold;
    }
    &__header {
      font-family: $font--subheading-alt; // @todo if this is a heading let is use defaults
      font-size: 20px;
      margin: 0 0 10px;
      color: $color-black;
    }
    &__content {
      text-align: left;
    }
    &__required {
      margin: 10px 0;
    }
    .prechat-form {
      &__signout {
        margin-bottom: 10px;
      }
      &__input {
        height: 40px;
        padding: 10px;
        overflow: hidden;
      }
      &__question textarea {
        height: 60px;
        padding: 0 10px;
      }
      &__input,
      &__question textarea {
        width: 100%;
        margin-bottom: 10px;
      }
      &__order-chat,
      &__beauty-chat {
        margin-bottom: 10px;
      }
      &__order-btn,
      &__beauty-btn {
        width: 100%;
        margin-bottom: 10px;
        img {
          width: 100%;
        }
      }
    }
  }
}
