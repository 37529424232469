.afterpay-banner {
  .hero-large__inner-vpos--top {
    top: 65%;
  }
  .hero-large__content--mobile {
    position: absolute;
    top: 260px;
    @include breakpoint($medium-up) {
      top: 144px;
      left: 144px;
    }
  }
  .afterpay-installment-message {
    color: $color-white;
    font-size: 18px;
    text-align: center;
    @include breakpoint($medium-up) {
      font-size: 25px;
    }
  }
}

.afterpay-working {
  width: auto;
  text-align: center;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    margin: 30px auto;
  }
}

.page-header__title {
  font-size: 36px;
  font-family: $font--heading-main;
  margin-bottom: 0;
}

.steps {
  display: block;
  @include breakpoint($medium-up) {
    display: flex;
  }
  .step {
    width: 90%;
    margin: 30px auto;
    @include breakpoint($medium-up) {
      width: 33%;
      margin: 0 auto;
      font-size: 18px;
    }
    &_name {
      font-size: 18px;
      margin: 25px;
      font-family: $font--subheading;
    }
    &_body {
      font-size: 13px;
    }
  }
}

.afterpay-requirements {
  text-align: center;
  &__title {
    font-size: 36px;
    font-family: $font--heading-main;
  }
}

.afterpay-access {
  text-align: center;
  margin: 0 auto;
  width: 90%;
  @include breakpoint($medium-up) {
    width: 100%;
  }
  &__title {
    font-size: 36px;
    line-height: 1;
    font-family: $font--heading-main;
  }
}

.afterpay-faq-section {
  width: auto;
  margin: 0 auto;
  .afterpay-faq {
    .afterpay-faq-title {
      text-align: center;
      font-size: 36px;
      line-height: 1;
    }
  }
  .collapsible-block {
    &__title {
      font-size: 18px;
    }
  }
}
